














































































































  import {
    getStateTranslation,
    projectTypes
  } from "../_helpers/projectMetadata";
  import CapsuleLabelInput from "../components/view_elements/CapsuleLabelInput.vue";
  import PeopleDefaultIcon from "../components/view_elements/PeopleDefaultIcon.vue";
  import PeopleCore from "../models/core/PeopleCore";
  import ProjectPeopleCore from "../models/core/project/ProjectPeopleCore";
  import ProjectStateCore from "../models/core/project/ProjectStateCore";
  import peopleService from "../services/core/peopleService";
  import PeopleIcon from "../view-models/PeopleIcon";

  export default {
    name: "Account",
    components: { PeopleDefaultIcon, CapsuleLabelInput },
    data() {
      return {
        loadedData: Boolean(false),
        name: String,
        lastName: String,
        email: String,
        jobPosition: String,
        date: undefined,
        projectsFields: [
          {
            label: this.$t("name"),
            key: "name",
            sortable: false
          },
          {
            label: this.$t("state"),
            key: "projectState",
            sortable: false
          },
          {
            label: this.$tc("client", 1),
            key: "client",
            sortable: false
          },
          {
            label: this.$t("startDate"),
            key: "startDate",
            sortable: false
          },
          {
            label: this.$t("endDate"),
            key: "endDate",
            sortable: false
          },
          {
            label: this.$t("closingDate"),
            key: "closingDate",
            sortable: false
          }
        ],
        text: {
          account: this.$t("account"),
          save: this.$t("save"),
          back: this.$t("back"),
          name: this.$t("name"),
          lastName: this.$t("lastName"),
          email: this.$t("email"),
          jobPosition: this.$t("jobPosition"),
          incorporationDate: this.$t("incorporationDate"),
          myProjects: this.$tc("myProjects")
        }
      };
    },
    mounted: async function () {
      await this.loadUser(this.getUser.getUsername).then(() => {
        this.resetData();
      });
      await this.loadProjectsPeopleCoreByUsername(this.getUser.getUsername);
      await this.loadProjects();
      await this.loadClients();
      this.loadedData = true;
    },
    computed: {
      userPeopleIcon: function (): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(this.getUser);
      },
      changedFields: function (): boolean {
        return !(
          this.name == this.getUser.getFirstName &&
          this.lastName == this.getUser.getLastName &&
          this.jobPosition == this.getUser.getJobPosition
        );
      }
    },
    methods: {
      async loadProjects(): Promise<any> {
        const userProjects: ProjectPeopleCore[] = this.getProjectPeople;
        for (const userProject of userProjects) {
          await this.loadProject(userProject.getProjectId);
        }
      },
      goBack() {
        this.resetData();
        this.$router.go(-1);
      },
      resetData() {
        this.name = this.getUser.getFirstName;
        this.email = this.getUser.getUsername;
        this.lastName = this.getUser.getLastName;
        this.jobPosition = this.getUser.getJobPosition;
        this.date = this.$moment(this.getUser.getIncorporationDate).format(
          "LL"
        );
      },
      save() {
        const userData = new PeopleCore({
          username: this.email,
          firstName: this.name,
          lastName: this.lastName,
          jobPosition: this.jobPosition,
          incorporationDate: new Date(this.getUser.getIncorporationDate),
          roleId: this.getUser.getRoleId,
          clientId: this.getUser.getClientId,
          language: this.$i18n.locale
        });
        peopleService.methods.putPeople(userData).then(() => {
          this.loadUser(this.getUser.getUsername).then(() => {
            this.resetData();
          });
        });
      },
      async projectsData(): Promise<
        {
          id: number;
          projectState: string;
          name: string;
          startDate: string;
          endDate: string;
          closingDate: string;
          client: string;
        }[]
      > {
        let result = [];
        if (this.getProjects && this.getUser && this.getClients) {
          for (const project of this.getProjects) {
            let client = this.getClients.find(
              client => client.getId == project.getClientId
            ).getName;
            if (!client) client = this.$t("notAssigned");
            let endDate;
            if (
              project.getProjectType == projectTypes.liabilityTemporary.name
            ) {
              endDate = this.$moment(project.getEndDate).format("LL");
            } else if (
              project.getProjectType == projectTypes.liabilityFull.name
            ) {
              endDate = this.$moment(project.getEndDate).format("LL");
            } else {
              endDate = "-";
            }
            let closingDate = project.getClosingDate;
            if (closingDate != null) {
              closingDate = this.$moment(closingDate).format("LL");
            } else {
              closingDate = "-";
            }
            const projectState: ProjectStateCore = await this.loadProjectState(
              project.getId
            );
            result.push({
              id: project.getId,
              name: project.getName,
              projectState: this.$t(getStateTranslation(projectState.getState)),
              startDate: this.$moment(project.getStartDate).format("LL"),
              endDate: endDate,
              closingDate: closingDate,
              client: client
            });
          }
        }
        return result.sort((p1, p2) => {
          if (p1.projectState < p2.projectState) return -1;
          else if (p1.projectState > p2.projectState) return 1;
          else if (p1.name < p2.name) return -1;
          else return 1;
        });
      }
    }
  };
