
















































































































































































  import { projectStates, projectTypes } from "../../_helpers/projectMetadata";
  import ProjectsList from "../../components/home/project/ProjectsList.vue";
  import ProjectsOverview from "../../components/home/project/ProjectsOverview.vue";
  import CapsuleLabelInput from "../../components/view_elements/CapsuleLabelInput.vue";
  import ConfirmationModal from "../../components/view_elements/ConfirmationModal.vue";
  import ProjectCore from "../../models/core/ProjectCore";
  import ProjectPeopleCore from "../../models/core/project/ProjectPeopleCore";
  import ProjectStateCore from "../../models/core/project/ProjectStateCore";
  import utils from "../../utils/utils";

  export default {
    name: "ProjectsTab",
    components: {
      ProjectsList,
      ProjectsOverview,
      ConfirmationModal,
      CapsuleLabelInput
    },
    data() {
      return {
        view: "list",
        text: {
          searchProject: this.$t("searchProject"),
          deleteAll: this.$t("deleteAll"),
          cancel: this.$t("cancel"),
          confirm: this.$t("confirm"),
          newProject: this.$t("newProject"),
          liability: this.$t(projectTypes.liability.i18n),
          // IMPORTANT: Defined in snake_case to match filter key name
          time_and_materials: this.$t(projectTypes.timeAndMaterials.i18n),
          capacity: this.$t(projectTypes.capacity.i18n),
          qbr: this.$tc(projectTypes.QBR.i18n, 1),
          csp: this.$tc(projectTypes.CSP.i18n, 1),
          active: this.$t(projectStates.active.i18n),
          closed: this.$t(projectStates.closed.i18n),
          removed: this.$t(projectStates.removed.i18n),
          activeProjects: this.$t("activeProjects"),
          closedProjects: this.$t("closedProjects"),
          removedProjects: this.$t("removedProjects")
        },
        mountedParent: false,
        sort: 1, //1:sort A to Z, 2:sort Z to A, 3:sort by StartDate, 4:sort by StartDate inverse
        filter: {
          projectName: "",
          state: projectStates.active.name,
          type: {
            LIABILITY: true,
            TIME_AND_MATERIALS: true,
            CAPACITY: true,
            QBR: true,
            CSP: true
          }
        },
        noneSelected: true,
        key: Number(0),
        activeString: projectStates.active.name,
        closedString: projectStates.closed.name,
        removedString: projectStates.removed.name
      };
    },
    mounted() {
      this.mountedParent = true;
    },
    computed: {
      projectsToDisplay(): ProjectCore[] {
        let projectToDisplay = [];
        let projectsToFilter = this.getUser.isAdmin()
          ? this.getProjects
          : this.filterUserProjects();

        for (const project of projectsToFilter) {
          if (this.projectPassFilter(project)) {
            projectToDisplay.push(project);
          }
        }
        this.fullProjectsSort(projectToDisplay, this.sort);
        return projectToDisplay;
      },
      currentUserProjects(): ProjectPeopleCore[] {
        const peopleOfProject: ProjectPeopleCore[] = this.getProjectPeople;

        return peopleOfProject.map(
          person =>
            new ProjectPeopleCore({
              username: person.getUsername,
              projectId: person.getProjectId
            })
        );
      }
    },
    methods: {
      getRemovedProjects(): ProjectCore[] {
        let projectsToDelete = [];
        for (const project of this.projectsToDisplay) {
          const lastProjectState: ProjectStateCore =
            this.getLastProjectStateByProjectId(project.getId);
          if (lastProjectState.getState == this.removedString) {
            projectsToDelete.push(project.getId);
          }
        }
        return projectsToDelete;
      },
      async deletePermanentlyProjects() {
        const projects = this.getRemovedProjects();
        if (projects.length) {
          for (const project of projects) {
            await Promise.all([
              this.deleteProjectDx(project),
              this.deleteProject(project)
            ]);
          }
        }
        this.$nextTick(async () => {
          await this.$bvModal.hide("modal-confirmation-all");
        });
      },
      switchView() {
        if (this.view == "list") {
          this.view = "card";
          this.filterUserProjects();
        } else {
          this.view = "list";
        }
      },
      evalTextVariable(key): string | null {
        try {
          return eval("this.text." + key);
        } catch (error) {
          try {
            return eval(key);
          } catch (errorWithoutThis) {
            this.$log.warn(
              "Could not find i18n text key " + key,
              errorWithoutThis
            );
            return null;
          }
        }
      },
      getSubtitleText(): string {
        switch (this.filter.state) {
          case "ACTIVE":
            return this.text.activeProjects;
          case "CLOSED":
            return this.text.closedProjects;
          case "REMOVED":
            return this.text.removedProjects;
          default:
            return "";
        }
      },
      // returns an array of id of the proyects
      filterUserProjects(): ProjectCore[] {
        this.key++;
        const currentUserProjectsIds: number[] = this.currentUserProjects.map(
          p => p.projectId
        );

        return this.getProjects.filter(p =>
          currentUserProjectsIds.includes(p.getId)
        );
      },
      //Sort all projects depending on sort option: 1:sort A to Z, 2:sort Z to A, 3:sort by StartDate, 4:sort by StartDate inverse
      fullProjectsSort(userProjects, option) {
        switch (option) {
          case 1:
          case 2:
            userProjects.sort((projectA, projectB) => {
              const projectsNameCompare = utils.compareWordsAlphabetically(
                projectA.getName,
                projectB.getName
              );
              return option == 2
                ? projectsNameCompare * -1
                : projectsNameCompare;
            });
            break;
          case 3:
          case 4:
            userProjects.sort((a, b) => {
              const startDateA = a.getStartDate;
              const startDateB = b.getStartDate;
              if (startDateA < startDateB) {
                return option == 4 ? 1 : -1;
              }
              if (startDateA > startDateB) {
                return option == 4 ? -1 : 1;
              }
              return 0;
            });
            break;
        }
      },

      // Checks if the projectTypes is LIABILITY, TIME_AND_MATERIALS, CAPACITY or QBR
      projectPassFilter(project: ProjectCore): boolean {
        let projectType = project.getProjectType;
        if (
          projectType == projectTypes.liabilityEconomic.name ||
          projectType == projectTypes.liabilityTemporary.name ||
          projectType == projectTypes.liabilityFull.name
        ) {
          projectType = projectTypes.liability.name;
        }
        if (!this.filter.type[projectType]) {
          return false;
        }
        if (
          this.getLastProjectStateByProjectId(project.getId).getState !=
          this.filter.state
        ) {
          return false;
        }
        const projectNameFilter = this.filter.projectName.trim().toLowerCase();
        if (
          projectNameFilter &&
          !project.getName.toLowerCase().includes(projectNameFilter)
        ) {
          return false;
        }
        return true;
      },
      checkFilter(projectType) {
        if (this.noneSelected) {
          this.noneSelected = false;
          const that = this;
          Object.keys(this.filter.type).forEach(function (key) {
            that.filter.type[key] = false;
          });
        }
        this.filter.type[projectType] = !this.filter.type[projectType];
      },
      checkAllUnselected() {
        if (
          !this.noneSelected &&
          Object.keys(this.filter.type).every(key => !this.filter.type[key])
        ) {
          this.noneSelected = true;
          const that = this;
          Object.keys(this.filter.type).forEach(function (key) {
            that.filter.type[key] = true;
          });
        }
      },
      goToCreateProject() {
        this.$router.push("/project-form");
      }
    }
  };
