


























  import { servicesExtensions } from "../../../_helpers/servicesExtensions";
  import utils from "../../../utils/utils";
  import ProjectClientRow from "./ProjectClientRow.vue";
  export default {
    name: "ProjectsOverview",
    components: { ProjectClientRow },
    props: {
      projects: Array, // List of project core
      projectsDx: Array,
      mountedParent: Boolean
    },
    data() {
      return {
        showCollapse: Boolean(true),
        text: {
          thereAreNoProjects: this.$t("thereAreNoProjects")
        },
        title: `${servicesExtensions.deliveryExperience.name} / ${this.$t(
          "projects"
        )}`
      };
    },
    computed: {
      getClientsIds() {
        let clientsIds = [];
        for (const project of this.projects) {
          if (
            !clientsIds.find(e => e == project["clientId"]) &&
            project["clientId"] != this.getUser.clientId
          ) {
            clientsIds.push(project["clientId"]);
          }
        }
        const mapped: {
          i: number;
          value: string;
        }[] = clientsIds.map((v, i) => {
          return { i, value: this.getClientById(v).getName };
        });
        mapped.sort((clientA, clientB) => {
          return utils.compareWordsAlphabetically(clientA.value, clientB.value);
        });

        return mapped.map(v => clientsIds[v.i]);
      }
    }
  };
