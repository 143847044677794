var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"project-row",attrs:{"id":_vm.id,"name":_vm.project.getName,"align-v":"center","aria-controls":_vm.project.getId},on:{"click":function($event){_vm.showCollapse = !_vm.showCollapse}}},[_c('div',{staticClass:"dropdown-btn-col",attrs:{"data-name":"collapse-services"}},[_c('div',[_c('b-button',{staticClass:"button-dropdown",staticStyle:{"box-shadow":"none !important","background-color":"transparent","border-color":"transparent","margin-top":"-5px"},style:({
            display: _vm.projectState == _vm.activeStateString ? 'auto' : 'none'
          })},[_c('div',{staticClass:"button-hover",style:({
              transform:
                _vm.showCollapse && _vm.projectState == _vm.activeStateString
                  ? 'rotateX(180deg)'
                  : ''
            })},[_c('FontAwesomeIcon',{staticStyle:{"height":"20"},attrs:{"icon":"fa-solid fa-chevron-down"}})],1)])],1)]),_c('div',{staticClass:"project-col-large text-truncate",attrs:{"data-name":"name"}},[_vm._v(" "+_vm._s(_vm.projectNameFormated(_vm.project.getName))+" ")]),_c('div',{staticClass:"project-col-medium",attrs:{"data-name":"type"}},[_vm._v(" "+_vm._s(_vm.showProjectTypeText(_vm.project.getProjectType))+" ")]),(_vm.getPeopleIcons.length)?_c('div',{staticClass:"project-col-small",attrs:{"data-name":"team"}},[_c('PeopleIcons',{attrs:{"people":_vm.getPeopleIcons,"id":'projects-tab-all-people' + _vm.project.getName}})],1):_c('div',{staticClass:"project-col-small"},[_c('span',[_vm._v("-")])]),_c('div',{staticClass:"project-col-small text-truncate",attrs:{"data-name":"client"}},[_vm._v(" "+_vm._s(_vm.clientNameFormated(_vm.getClientById(_vm.project.getClientId).getName))+" ")]),_c('div',{staticClass:"project-col-medium",attrs:{"data-name":"start-date"}},[_vm._v(" "+_vm._s(_vm.changeDateFormat(_vm.project.getStartDate))+" ")]),_c('div',{staticClass:"project-col-small",attrs:{"data-name":"end-date"}},[_c('div',[_vm._v(" "+_vm._s(_vm.project.getEndDate ? _vm.changeDateFormat(_vm.project.getEndDate) : "-")+" ")])]),_c('div',{staticClass:"project-col-small",attrs:{"data-name":"closing-date"}},[(_vm.projectState != _vm.activeStateString)?_c('div',[_vm._v(" "+_vm._s(_vm.changeDateFormat(_vm.project.getClosingDate))+" ")]):_vm._e()]),_c('div',{staticClass:"dropdown-btn-col",staticStyle:{"margin-top":"-7px","margin-left":"0px"},attrs:{"data-name":"actions"}},[(_vm.getUser.isAdminOrMgr() && _vm.getUser.isSogeti())?_c('ContextMenu',{attrs:{"project":_vm.project,"projectState":_vm.projectState},on:{"collapse":_vm.collapse}}):_vm._e()],1)]),_c('ServicesCollapse',{key:_vm.showCollapse,attrs:{"id":_vm.project.getId,"state":_vm.projectState,"servicesOfProject":_vm.servicesByProject,"showCollapse":_vm.showCollapse}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }