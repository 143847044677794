import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    /* GLOBAL */
    ...mapActions("language", ["setLanguage"]),
    ...mapActions("loadingComponents", [
      "addLoadingComponent",
      "removeLoadingComponent",
      "clearLoadingComponents"
    ]),
    ...mapActions("message", ["setMessage"]),
    /* CORE */
    ...mapActions("capacityTypeCore", [
      "loadCapacityTypes",
      "postCapacityType"
    ]),
    ...mapActions("categoryCore", ["loadCatalog", "loadCategories"]),
    ...mapActions("clientCore", [
      "loadClients",
      "postClient",
      "putClient",
      "deleteClient",
      "postClientService",
      "deleteClientService"
    ]),
    ...mapActions("extensionCore", [
      "loadExtensions",
      "loadCompatibleExtensionsByClientId",
      "loadExtensionService"
    ]),
    ...mapActions("peopleCore", [
      "loadPeople",
      "loadPeoplesCoreByClientIdAndSogeti",
      "loadProjectsPeopleCoreByUsername",
      "postPeople",
      "postProjectPeople",
      "putPeople",
      "deletePeople",
      "deleteProjectPeople",
      "logOut",
      "loadUser"
    ]),
    ...mapActions("projectCore", [
      "loadProjectStates",
      "loadProjectState",
      "loadProject",
      "loadProjects",
      "loadProjectsByClient",
      "fetchProjectService",
      "postProject",
      "postProjectState",
      "postProjectServices",
      "putProject",
      "deleteProject",
      "deleteProjectServices",
      "validateProjectName"
    ]),
    ...mapActions("serviceCore", [
      "loadServices",
      "loadHiredServicesByClientId",
      "loadActiveServicesByClientId"
    ]),
    /* DELIVERY EXPERIENCE */
    ...mapActions("documentDx", [
      "loadDocumentsDxByProjectId",
      "postDocumentDxAndBlob",
      "putDocumentDx",
      "deleteDocumentDx",
      "updateDocumentDxLocal",
      "deleteDocumentDxLocal"
    ]),
    ...mapActions("eventDx", [
      "loadEventsDx",
      "loadEventDx",
      "loadEventsDxByProjectId",
      "postEventDx",
      "putEventDx",
      "putEventDxBaseline",
      "postEventDxBaseline",
      "deleteEventDx",
      "updateEventDxLocal",
      "deleteEventDxLocal"
    ]),
    ...mapActions("notificationDx", [
      "loadNotificationsByUser",
      "postNotificationDx",
      "markNotificationAsRead",
      "deleteNotificationDxLocal"
    ]),
    ...mapActions("peopleDx", [
      "loadPeopleDx",
      "loadPeopleDxInCurrentProject",
      "loadPeopleDxByProjectId",
      "loadPeopleDxByUsername",
      "postPeopleDx",
      "putPeopleDx",
      "deletePeopleDx",
      "updatePeopleDxLocal",
      "deletePeopleDxLocal",
      "pushPeopleDxColorIdLocal"
    ]),
    ...mapActions("projectDx", [
      "loadProjectsDx",
      "loadProjectDx",
      "postProjectDx",
      "putProjectDx",
      "deleteProjectDx"
    ]),
    ...mapActions("requestDx", [
      "loadRequestsDxByProjectId",
      "loadRequestDx",
      "postRequestDx",
      "putRequestDx",
      "deleteRequestDx",
      "deleteRequestDxLocal"
    ]),
    ...mapActions("stageDx", [
      "loadStagesDxByProjectId",
      "postStageDx",
      "postStageDxBaseline",
      "putStageDx",
      "putStageDxBaseline",
      "deleteStageDx",
      "deleteStageDxLocal"
    ]),
    ...mapActions("tagDx", [
      "loadTagsDx",
      "postTagsDx",
      "putTagsDx",
      "checkRemainingProjectTag",
      "deleteTagDxLocal"
    ]),
    ...mapActions("teamDx", ["loadTeamsDx", "putTeamDx", "deleteTeamDxLocal"]),
    ...mapActions("versionDx", [
      "loadVersionsDx",
      "loadVersionsDxByProjectId",
      "fetchLastProjectVersionDx",
      "postVersionDx",
      "deleteVersionDxLocal"
    ]),
    /* CSP */
    ...mapActions("customerCSP", ["loadCustomersCSP"]),
    ...mapActions("customerSummaryCSP", [
      "loadCustomersSummaryCSP",
      "loadCustomerSummaryCSPById"
    ]),
    ...mapActions("marginCSP", [
      "loadMarginsCSP",
      "postMarginCSP",
      "putMarginCSP"
    ]),
    ...mapActions("pendingOperationCSP", [
      "loadPendingOperationsCSP",
      "loadPendingOperationCSPBySubscriptionId",
      "postPendingOperationCSP",
      "putPendingOperationCSP"
    ]),
    ...mapActions("productsCSP", [
      "loadProductsCSPByType",
      "loadProductCSPSkusByProductId",
      "fetchCSPProductCatalogItemIdAndTerms",
      "fetchCSPProductPrice",
      "createCSPCart",
      "purchaseCSPOrder"
    ]),
    ...mapActions("subscriptionCSP", [
      "loadSubscriptionCSPIdsByTenantId",
      "loadSubscriptionCSPByTenantIdAndSubscriptionId",
      "loadSubscriptionCSPAvailabilities",
      "putSubscriptionCSP",
      "clearSubscriptionsCSP"
    ]),
    /* SPIDER LINKS CHECKER */
    ...mapActions("analysisSLC", [
      "loadLastAnalysis",
      "loadPreviousAnalysis",
      "loadNextAnalysis",
      "postAnalysis"
    ])
  },
  computed: {
    /* GLOBAL */
    ...mapGetters("language", ["getLanguage"]),
    ...mapGetters("loadingComponents", ["getLoadingComponents"]),
    ...mapGetters("message", ["getText", "getTime", "getType"]),
    /* CORE */
    ...mapGetters("capacityTypeCore", [
      "getCapacityTypes",
      "getCapacityTypeById"
    ]),
    ...mapGetters("categoryCore", ["getCategories"]),
    ...mapGetters("clientCore", ["getClients", "getClientById"]),
    ...mapGetters("extensionCore", [
      "getExtensions",
      "getExtensionByName",
      "getExtensionServiceByServiceId"
    ]),
    ...mapGetters("peopleCore", [
      "getUser",
      "getPeople",
      "getPeopleByUsernamesList",
      "getProjectPeople",
      "getProjectPeopleByUsername",
      "getPeopleByUsername",
      "getAvailablePeople",
      "getAvailablePeopleByClientIdAndProject"
    ]),
    ...mapGetters("projectCore", [
      "getProjects",
      "getProjectById",
      "getUserProjectsByClientId",
      "getLastProjectStateByProjectId"
    ]),
    ...mapGetters("serviceCore", [
      "getServices",
      "getServiceById",
      "getServiceByName",
      "getServicesByCategoryId",
      "getActiveServicesByProjectId",
      "getPreactivatedServicesByProjectId",
      "getActiveServices",
      "getHiredServicesByClientId",
      "getServicesByProjectId"
    ]),
    /* DELIVERY EXPERIENCE */
    ...mapGetters("documentDx", [
      "getDocumentDx",
      "getDocumentsDxByProjectId",
      "getDocumentsDxByEventId",
      "getDocumentsDxByStageId",
      "getDocumentsDxByRequestId",
      "getDocumentsDxByPeopleId"
    ]),
    ...mapGetters("eventDx", [
      "getEventDx",
      "getEventsDxByProjectId",
      "getEventsDxByRelated",
      "getEventsByRecurrenceId"
    ]),
    ...mapGetters("notificationDx", [
      "getNotifications",
      "getNotificationById",
      "getNotificationsDxByProjectId"
    ]),
    ...mapGetters("peopleDx", [
      "getPeopleDxColorIds",
      "getPeopleDxInCurrentProject",
      "getUserPermissions",
      "getPeopleDxById",
      "getPeopleDxByProjectId",
      "getPOCsByProjectId",
      "getPeopleDxByEventId",
      "getPeopleDxByStageId",
      "getUserDxInProject"
    ]),
    ...mapGetters("projectDx", ["getProjectsDx", "getProjectDx"]),
    ...mapGetters("requestDx", [
      "getRequestsDxByProjectId",
      "getRequestsDxByProjectIdAndStatus",
      "getRequestDx"
    ]),
    ...mapGetters("stageDx", ["getStageDx", "getStagesDxByProjectId"]),
    ...mapGetters("tagDx", ["getProjectTags"]),
    ...mapGetters("teamDx", ["getTeamDxByProjectId"]),
    ...mapGetters("versionDx", [
      "getVersionsDxByProjectId",
      "getLastProjectVersionDx"
    ]),
    /* CSP */
    ...mapGetters("customerCSP", ["getCustomersCSP"]),
    ...mapGetters("customerSummaryCSP", [
      "getCustomersSummaryCSP",
      "getCustomerSummaryCSPById"
    ]),
    ...mapGetters("marginCSP", ["getMarginCSPByCustomerId"]),
    ...mapGetters("pendingOperationCSP", [
      "getPendingOperationsCSPByTenantId",
      "getPendingOperationCSPBySubscriptionId"
    ]),
    ...mapGetters("productsCSP", ["getProductsCSP", "getProductCSPSkus"]),
    ...mapGetters("subscriptionCSP", [
      "getSubscriptionsCSP",
      "getSubscriptionCSPById"
    ]),
    /* SPIDER LINKS CHECKER */
    ...mapGetters("analysisSLC", ["getLastAnalysisResult"])
  }
};
