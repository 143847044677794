import axios from "axios";
import SubscriptionCSP from "../../models/csp/SubscriptionCSP";
import baseEndpoint from "./servicePartnerCenter";

export default {
  methods: {
    getSubscriptionIdsByTenantId: async function (tenantId: string) {
      const endpoint =
        baseEndpoint + "/customers/" + tenantId + "/subscriptions/";
      return await axios
        .get(endpoint)
        .then(response => {
          return response.data.items
            .filter(
              item =>
                item.status != "deleted" &&
                (!item.productType ||
                  item.productType.id != "Azure" ||
                  item.offerName != "Azure plan")
            )
            .map(item => {
              const subscriptionCSP = new SubscriptionCSP(undefined);
              subscriptionCSP.setId = item.id;
              return subscriptionCSP;
            });
        })
        .catch(error => {
          return error;
        });
    },
    getSubscriptionByTenantIdAndSubscriptionId: async function (
      tenantId: string,
      subscriptionId: string
    ) {
      const endpoint =
        baseEndpoint +
        "/customers/" +
        tenantId +
        "/subscriptions/" +
        subscriptionId;
      return await axios
        .get(endpoint)
        .then(response => {
          return new SubscriptionCSP({
            id: response.data.id,
            friendlyName: response.data.friendlyName,
            quantity: response.data.quantity,
            billingType: response.data.billingType,
            status: response.data.status,
            offerName: response.data.offerName,
            billingCycleEndDate: response.data.billingCycleEndDate,
            commitmentEndDate: response.data.commitmentEndDate,
            termDuration: response.data.termDuration,
            billingCycle: response.data.billingCycle,
            refundableQuantity: response.data.refundableQuantity,
            autoRenewEnabled: response.data.autoRenewEnabled,
            productId: response.data.offerId.split(":")[0],
            links: response.data.links
          });
        })
        .catch(error => {
          return error;
        });
    },
    putSubscription: async function (
      tenantId: string,
      subscriptionId: string,
      body: Object
    ) {
      const endpoint =
        baseEndpoint +
        "/customers/" +
        tenantId +
        "/subscriptions/" +
        subscriptionId;

      await axios.patch(endpoint, body);
    },
    getAvailabilities: async function (availabilitiesEndpoint: string) {
      const endpoint = baseEndpoint + availabilitiesEndpoint;
      try {
        return await axios.get(endpoint).then(response => {
          return response.data.terms;
        });
      } catch (error) {
        throw error;
      }
    }
  }
};
