export default {
  spanish: "Spanish",
  english: "English",
  hello: "Hello",
  signOut: "Sign out",
  search: "Search",
  notifications: "Notifications",
  noNotifications: "There are no notifications",
  noNewNotificationsToRead: "No new notifications to read",
  hasBeenResolved: "has been resolved",
  hasBeenClosed: "has been resolved",
  hasBeenCommented: "has been commented",
  seeAllNotifications: "See all notifications",
  service: "Service | Services",
  servicesLowCase: "service",
  categories: "Category",
  hire: "Hire",
  hired: "Hired | Hired",
  all: "All | All",
  clear: "Clear",
  legalWarning: "Legal warning",
  privacyPolicy: "Privacy policy",
  informationSecurityPolicy: "Information security policy",
  contact: "CONTACT",
  mainContacts: "Main contacts",
  responsibleOf: "Responsible of",
  date: "Date",
  user: "User | Users",
  userLowCase: "user | users",
  exMember: "(Ex-member)",
  external: "External",
  eventPeopleTitle: "Event members",
  stagePeopleTitle: "Stage members",
  requestPeopleTitle: "Request author",
  requestThreadTitle: "Comments",
  requestRelatedTitle: "Related events",
  progress: "Progress | PROGRESS",
  eventsCompleted: "Events completed",
  stagesCompleted: "Stages completed",
  noMembers: "This {entity} has no members. | This {entity} has no members.",
  lackOfPermissionsSection: "You don't have permissions to see this section.",
  lackOfPermissionsPage: "You don't have permissions to see this page.",
  seeAllMembers: "See all members",
  onlySelectedMembers: "Only selected members",
  itemAuthor: "Item author",
  itemName: "Item name",
  nobody: "Nobody",
  name: "Name",
  nickname: "Nickname",
  enterName: "Enter name",
  enterLastName: "Enter last Name",
  enterEmail: "Enter email",
  enterJobPosition: "Enter job position",
  enterProjectRole: "Enter project role",
  notSpecifiedJobPosition: "Job position not specified",
  related: "Related",
  author: "Author",
  readers: "Readers",
  noMembersForDocReaders: "This item has no members.",
  version: "Version | Versions",
  download: "Download | Download",
  downloadAll: "Download all documents",
  monthsView: "{months} month view | {months} months view",
  lastMonths: "Last {months} months",
  allProject: "All project",
  affectedEvents: "Affected events",
  affectedStages: "Affected stages",
  objective: "Objective | Objectives",
  member: "Member | Members",
  hour: "Hour | Hours",
  seeAll: "See all",
  seeMore: "See more",
  admin: "Administrator | Administrators",
  youAreAdmin: "You are admin",
  makeAdmin: "Make administrator",
  manager: "Manager | Managers",
  welcomeBack: "Welcome back",
  enter: "Enter",
  for: "For | for",
  moreInformation: "More information | more information",
  visitWebsite: "visit website",
  usernameIsRequired: "Username is required",
  password: "Password | password",
  email: "Email",
  signUp: "Sign Up",
  signUpUser: "Sign up new user",
  userRegistration: "User Registration",
  firstName: "First Name",
  lastName: "Last Name",
  username: "Username",
  language: "Language",
  confirm: "Confirm",
  cancel: "Cancel",
  restore: "Restore",
  deletePermanently: "Delete permanently",
  deleteAll: "Delete all",
  project: "Project | project",
  projects: "Projects",
  myProjects: "My projects",
  newProject: "New project",
  duration: "Duration | duration",
  team: "Team",
  client: "Client | Clients",
  clientManagement: "Client management",
  role: "Role",
  startDate: "Start date",
  projectType: "Project Type",
  projectTeam: "Team",
  teamMember: "Team member",
  noTeamMembers:
    "There is no one assigned to the project team. You can add someone by creating a <b>{event}</b> event.",
  start: "Start",
  end: "End",
  endDate: "End date",
  closingDate: "Closing date",
  deletedDate: "Deletion date",
  modified: "Modified",
  left: "Left",
  daysFor: "days for",
  invalidEmailAddress: "Invalid email address",
  unableToConnectSogetiSpainHubServices:
    "Unable to connect Sogeti Hub services",
  account: "Account",
  viewAccount: "View account",
  action: "Action | Actions",
  selectAnOption: "Select an option",
  select: "Select",
  selectOne: "Select one",
  selectService: "Select a service",
  selectDate: "Select a date",
  selectAction: "Select an action",
  selectLanguage: "Select language",
  selectRole: "Select role",
  selectType: "Select type",
  selectAnItem: "Select an item",
  selectAnEvent: "Select an event",
  selectEvents: "Select events",
  selectAStage: "Select a stage",
  selectStages: "Select stages",
  selectAFile: "Select a file",
  selectScope: "Select a scope to edit its artifacts",
  selectAEventStage: "Select an event or a stage.",
  selectProjectType: "Select the project type",
  selectClient: "Select the client",
  selectOrAddUnit: "Select or add unit",
  selectOrAddClient: "Select or add client",
  selectOrAddTag: "Select/add tag",
  selectVersion: "Select a version",
  selectPerson: "Select a person",
  selectNotificationsPolicy: "Select a policy",
  categorySelection: "Select a category",
  serviceSelection: "Select a service",
  extensionSelection: "Select an extension",
  membersSelected: "members selected",
  noClientSelected: "Select a client to manage their information",
  dropdownAdd: "'Enter' to create",
  information: "Information",
  alert: "Alerts",
  warning: "Warning",
  ascendant: "Ascending",
  descending: "Descending",
  resultsFound: "results found",
  cleanFilters: "Clean filters",
  page: "Page | PAGE",
  of: "of | OF",
  previous: "Previous",
  preview: "Preview",
  next: "Next",
  nextEvent: "Next event",
  nextStage: "Next stage",
  currentStage: "Current stage | Current stages",
  description: "Description",
  the: "The | the",
  edit: "Edit",
  save: "Save",
  sortBy: "Sort by",
  editProject: "Edit project",
  addNewJob: "Enter to add new job",
  incorporationDate: "Incorporation date",
  incorporation: "Incorporation",
  thereAreNoProjects: "There are no projects",
  noServicesYetFor: "There are no active services yet for",
  serviceNotHired: "This service is not hired.",
  thereAreNoExtensions: "This service has no extensions.",
  create: "Create",
  editRemove: "Edit/Remove",
  newVersionMsg:
    "There is a new version available. Refresh page to see changes.",
  newEvent: "New event",
  newStage: "New stage",
  addRelatedEvent: "Add related event",
  editEvent: "Edit event",
  editStage: "Edit stage",
  editRequest: "Edit request",
  eventTitle: "Event Title",
  title: "Title",
  type: "Type",
  attachments: "Attached | Attachments",
  searchProject: "Search project",
  searchAMember: "Search a member",
  searchSomeone: "Search someone",
  delete: "Delete",
  teamManagement: "Team management",
  permissionsManagement: "Permissions management",
  newDocument: "New document",
  not_added_document: "No document added",
  accept: "Accept",
  extension: "Extension | Extensions",
  noServiceQBR: "Service not available for QBR projects.",
  from: " from ",
  event: "Event | event",
  events: "Events",
  recurrence: "Recurrence",
  deleteEvent: "Delete event",
  deleteRecurrenceEvent: "Delete series",
  deleteInfoRecurrenceEvent:
    "This event belongs to an event series, do you want to delete only the event or the entire series?",
  eventDescChangeRequest:
    "Represents a request for a change in liability-related aspects of the project.",
  eventDescReload:
    "Indicates a reload of the total amount of capacity units of the project.",
  eventDescBilling:
    "Refers to a billing-related activity or process in the project.",
  eventDescConsume:
    "Indicates a consumption of capacity units in certain project activities.",
  eventDescQBR:
    "Stands for Quarterly Business Review, for project progress evaluation, performance, and goals during a specific quarter.",
  eventDescCSPOperation: "Refers to a performed CSP operation.",
  eventDescActivity: "Represents a general project activity or task.",
  eventDescKickOff: "Marks the beginning of the project.",
  eventDescFollowUp:
    "Indicates a follow-up meeting or activity related to the project.",
  eventDescMilestone:
    "Represents significant milestones or achievements in the project.",
  eventDescClosing:
    "Refers to the closing or completion of a project phase or the entire project.",
  eventDescMajorIssue:
    "Warns about a major issue or blocker encountered in the project.",
  eventDescMinorIssue: "Denotes a minor issue or problem faced in the project.",
  eventDescProposalResolution:
    "Provides the proposal or resolution of an open matter or demand in the project.",
  eventDescVirtualVisit:
    "Refers to a scheduled virtual meetings or demos for the project.",
  eventDescWorkshopTraining:
    "Schedules a workshop or training session conducted as part of the project.",
  eventDescNewIncorporation:
    "Represents the addition of a new individual resource into the project.",
  eventDescUnassignment:
    "Indicates the unassignment of a resource from the project.",
  requestDescServiceRequest: "Demands a service activation for the project.",
  requestDescQuestion: "Raises a question or inquiry relating to the project.",
  requestDescVirtualVisitRequest:
    "Requests a virtual meeting or demo about the project.",
  stage: "Stage | stage",
  stages: "Stages",
  today: "Today",
  projectPeopleIncludedByDefault:
    "Project members will be included by default if no one has been selected.",
  issues: "Issues",
  dragAndDropOr: "Drag and drop a file here (max 20MB)",
  fileTooLarge: "The file is too large. Limit is 20MB",
  fileTooShort: "The file size has to be greater than 0",
  fileFormat: "The format of this file is not valid",
  noDocumentsUploaded: "No uploaded documents visible for you.",
  maxSizeDocs: "Drag and drop a file here (max 20MB).",
  versionOverwrite: "A new version will be created for the existing file.",
  versionCopy:
    "The existing file will be kept and a copy will be generated for the uploaded file.",
  upload: "Upload",
  new: "New",
  profile: "Profile",
  unhiredServicesForCategory: "There are no hired services in this category.",
  servicesHiring: "Services hiring",
  month: "month | months",
  basicInformation: "Basic information",
  saveContinue: "Save and continue",
  saveExit: "Save and exit",
  liability: "Liability",
  liabilityDesc:
    "Project enclosed to a budget, timeframe, or both as applicable, allows focus on the evolution of events, stages, and the project lifecycle itself.",
  liabilityEconomic: "Economic Liability | Economic liability",
  liabilityTemporary: "Temporary Liability | Temporary liability",
  liabilityFull: "Full Liability | Full liability",
  liabEconomic: "Economic liab.",
  liabTemporary: "Temporary liab.",
  liabFull: "Full liab.",
  timeAndMaterials: "Time & Materials",
  timeAndMaterialsDesc:
    "Project based on resource allocation, it focuses on the evolution of incorporations and de-allocations to the project team during its course.",
  capacity: "Capacity",
  capacityDesc:
    "Project focused on the evolution of the consumption of certain units of capacity or dedication to its execution, usually in terms of working hours or working days.",
  qbr: "QBR | qbr",
  qbrDesc:
    "Project indicated for quarterly executive follow-up meetings using Quarterly Business Reviews (QBR), organized in a calendar.",
  csp: "CSP | csp",
  cspDesc:
    "Project focused on self-management and purchase of Microsoft Cloud licenses and products thanks to the Cloud Service Provider (CSP) service as a Microsoft Partner, which summarizes the totals of licenses, subscriptions, Azure Plans, and accumulated cost.",
  close: "Close",
  collapse: "Collapse",
  activate: "Activate",
  deactivate: "Deactivate",
  confirmation: "Are you sure you want to deactivate ",
  cancelService: "Are you sure you want to cancel ",
  includedExtensions: "Included extensions",
  bookmarks: "Bookmarks",
  activeToday: "Active today",
  thereAreNoBookmarks: "There are no bookmarks.",
  amount: "Amount",
  pending: "Pending",
  initialCapacity: "Initial capacity",
  reloaded: "reloaded",
  consumed: "Consumed | consumed",
  billed: "billed",
  modify: "Modify",
  eventsOccurred: "Occurred events",
  eventsPending: "Pending events",
  majorIssue: "Major Issue | Major Issues",
  minorIssue: "Minor Issue | Minor Issues",
  configuration: "Configuration",
  projectName: "Project name",
  enterValue: "Enter value",
  underConstructionTitle: "Site under construction.",
  underConstructionText: "Content will be available soon.",
  error404title: "This page is not available",
  error404text:
    "The link may be broken, or the page page may have been removed.​",
  error500title: "Oops, this is unexpected…​",
  error500text: "An error has occurred and we’re working on it.​",
  error403title: "It looks like you can't access this page.",
  error403text:
    "You may not have enough permissions or your are not authorized.",
  errorTitle: "Sorry for the inconvenience!​​",
  errorText: "An error has occurred or you're not authorized.​",
  noProjectsNavbar: "No projects available",
  actuaState: "Actual state",
  view: "View | View",
  current: "Current",
  pendingEvents: "Pending events",
  pendingStages: "Pending stages",
  noAffectations: "No affectations",
  theServiceHasNoExtensions: "The service has no extensions.",
  activated: "Activated",
  generateNotes: "Generate notes",
  pendingToOccur: "Pending to occur",
  pendingToConfirm: "Pending to confirm",
  occurred: "Occurred",
  cancelled: "Cancelled",
  itemNotFound: "Item not found.",
  noElementsFound: "No elements found.",
  listEmpty: "List is empty.",
  invalidUnitType: "First letter in uppercase and the rest in lowercase.",
  amountUnits: "Capacity units",
  tag: "Tag | Tags",
  filterTags: "Filter tags",
  filterItems: "Filter items",
  hideStages: "Hide/Show stages",
  filter: "Filter",
  wantToConfirmDeleteDoc: "Are you sure you want to delete this document?",
  messageDeleteDoc: "This action will delete <b>{docName}</b>.",
  wantToConfirmDeletePeople: "Are you sure you want to delete this user?",
  messageDeletePeople:
    "This action will automatically delete everything related to the user.",
  wantToConfirmDeleteClient: "Are you sure you want to delete this client?",
  messageDeleteClient:
    "This action will automatically delete everything related to the client.",
  wantToConfirmProjectClosing: "Do you want to confirm project closing?",
  messageCloseProject:
    "This action automatically finalize all the activities for the selected project.",
  wantToConfirmProjectDeleting: "Do you want to confirm project deleting?",
  wantToConfirmProjectRestoring:
    "Are you sure you want to restore this project?",
  wantToConfirmProjectDeletingPermanently:
    "Are you sure you want to delete permanently this project?",
  wantToConfirmAllProjectsDeletingPermanently:
    "Are you sure you want to delete permanently all projects?",
  messageRemoveProject:
    "This action automatically erase all the activities for the selected project.",
  messageRestoreProject: "This action will restore the selected project.",
  messageDeletePermanentlyProject:
    "This action will delete permanently the selected project and all its data.",
  messageDeletePermanentlyAllProjects:
    "This action will delete permanently all removed projects and all their data.",
  participantsAndDocs: "Participants & Docs",
  active: "Active",
  closed: "Closed",
  removed: "Removed",
  noNextEvent: "There is no next event",
  noCurrentStage: "There is no current stage",
  noContacts: "No contacts",
  noTeam: "No team",
  participants: "Participants",
  newReport: "New report",
  creationDate: "Creation date",
  eventDate: "Event date",
  addMaxThreeObjs: "Add a maximum of 3 objectives",
  actionsReview: "Actions review",
  person: "Person",
  state: "State",
  objectiveDescription: "Objective description",
  projectDescription: "Project description",
  completed: "Completed",
  inProgress: "In progress",
  locked: "Locked",
  newActions: "New actions",
  revisionDate: "Revision date",
  thereAreNoParticipants: "There are no participants",
  thereAreNoDocs: "There are no documents",
  clientLogo: "ClientLogo",
  report: "Report",
  created: "created",
  index: "Index",
  eventInformation: "Event information",
  eventObjs: "Event objectives",
  documentation: "Documentation",
  reviewLastActions: "Actions review",
  agreedActions: "New actions",
  clientName: "Client name",
  clientNameExist: "This client name already exists",
  docName: "Filename",
  authorName: "Author name",
  responsible: "Responsible",
  lastActionsReview: "Actions Review",
  lastActionsReviewText:
    "On the table, there are actions pending to review during the event, responsible and corresponding states",
  newActionsText:
    "On the table, there are new actions to do, responsible and execution dates",
  eventReport: "EventReport",
  notAssigned: "Not assigned",
  noAvaliableVersions: "No avaliable versions",
  replanning: "Replanning",
  eventName: "Event name",
  stageName: "Stage name",
  eventType: "Event type",
  differenceDays: "Days of difference",
  assignedBaseline: "Assigned baseline",
  dependence: "Dependence | Dependences",
  request: "Request | request",
  requests: "Requests",
  comment: "Comment | comment",
  comments: "Comments | comments",
  issue: "Issue",
  send: "Send",
  serviceRequest: "Service Request",
  virtualVisit: "Virtual visit",
  question: "Question",
  thereAreNoOpenRequests: "There are no open requests.",
  thereAreNoRequests: "There are no requests.",
  requestSended: 'The request "{issue}" has been registered in SogetiHub',
  requestCommented: 'A new comment has been added to request "{issue}".',
  requestChangedStatus: 'The request "{issue}" has been {status}',
  requestDetails: "Request details",
  commentDetails: "Comment details",
  subject: "Subject",
  document: "Document | Documents",
  documentLowCase: "document | documents",
  eventDocuments: "Event attached docs",
  stageDocuments: "Stage attached docs",
  requestDocuments: "Request attached docs",
  requestLink:
    'See the request at <a href="{link}" target="_blank">SogetiHub</a>.',
  thankYou: "Thank you",
  postpone: "Postpone",
  and: "and",
  or: "or",
  durationYears: "1 year | {number} years",
  durationMonths: "1 month |{number} months",
  durationDays: "1 day | {number} days",
  maxInputAlert: "Max input",
  samedayEvents: "Events of day: ",
  reservedRights: `All rights reserved by SogetiLabs. Copyright © ${new Date().getFullYear()}`,
  kpiNotAvailable: "KPI not available",
  eventAffecPred:
    "This event is already affected by another event, which is its predecessor.",
  stageAffecPred:
    "This stage is already affected by another stage, which is its predecessor.",
  affected: "Affected",
  predecessor: "Predecessor",
  minAmountAlert: "The amount cannot be 0.",
  projectNameExist: "This project name already exists",
  projectTypeCSPNotAvailable:
    "CSP Panel service must be hired to create this project.",
  projectIcon: "Icon",
  projectHeader: "Header image",
  messageAlertError: "The action could not be completed",
  messageAlertSuccess: "Action completed",
  status: "Status",
  openRequests: "Open Requests",
  requestStatusOpen: "Open",
  requestStatusResolved: "Resolved",
  requestStatusClosed: "Closed",
  requestStatusUpdated: "updated",
  subscriptionId: "Subscription ID",
  subscription: "Subscription | Subscriptions",
  subscriptionName: "Subscription Name",
  licences: "Licences",
  licence: "licence(s)",
  azurePlans: "Azure Plans",
  currentCost: "Current Cost",
  newClient: "New client",
  editClient: "Edit client",
  lifetime: "Lifetime",
  timelineItem: "Timeline item",
  back: "Back",
  serviceDescDeliveryExperience:
    "Portal for interaction with Sogeti services with access to the team, service's timeline, requests, documents associated and service's statistics.",
  serviceDescQualityEye:
    "Data insights to support quality assurance activities and decisions.",
  serviceDescCSPPanel:
    "Dashboard of information and consumption of Microsoft Cloud services and resources consumed through Sogeti's Cloud Service Provider services.",
  serviceDescSpiderLinksChecker:
    "Tool that uses scraping and crawling algorithms to analyze a website and find broken links.",
  newIncorporationDesc: "New incorporation to the project:<br>",
  unassignmentDesc: "Unassigned a person from the project:<br>",
  cspCustomerInput: "Enter the CSP customer name",
  docsVisible: "Make attached docs visible at project level.",
  docsSelectedMembersVisible:
    "The attached docs now will be visible only for selected members.",
  docsSelectedItemMembersVisible:
    "The attached docs now will be visible only for the selected item members.",
  docsAllMembersVisible:
    "The attached docs now will be visible for all project members.",
  noRelatedEvent: "There are no related events",
  requestNoTag: "This request has no tags",
  requestIssue: "Request issue",
  showEventsTooltips: "Show details | Hide details",
  noTimelineItemDescription: "This timeline item has no description.",
  noRequestDescription: "This request has no description",
  pointOfContact: "Point of Contact",
  projectPointOfContact: "Points of contact of the project",
  writeEmail: "Click to write an email.",
  notifyTo: "Notify to:",
  allMembers: "All members",
  notificationTheEvent: "the event",
  notificationTheRequest: "the request",
  notificationTheStage: "the stage",
  notificationThread: "has <b> commented </b> the request",
  notificationCreated: "has <b> created </b> {item}",
  notificationEdited: "has <b> edited </b> {item}",
  notificationDeleted: "has <b> deleted </b> {item}",
  notificationDocUploaded: "has <b> uploaded </b> a document in",
  notificationDocDeleted: "has <b> deleted </b> a document in",
  notificationDocChanged: "has done <b> changes </b> in the documents in",
  notificationDocUploadedProject: "has <b> uploaded </b> a document",
  notificationDocDeletedProject: "has <b> deleted </b> a document",
  notificationRescheduled: "has <b> rescheduled </b> {item}",
  noAttachDocsClosedRequests:
    "The selected request is already closed and no more docs can be attached to it.",
  urlExample: "https://example.com",
  urlInvalid: "Not a valid URL",
  analysis: "Analysis",
  results: "Results",
  depth: "Depth",
  executionDate: "Execution Date",
  startAnalysis: "Start Analysis",
  optional: "Optional",
  element: "Element",
  link: "Link",
  origin: "Origin",
  brokenLinksHeader: "Broken links table",
  emptyAnalysis: "First, start an analysis",
  emptyLinks: "There aren't any broken links",
  linksPass: "PASSED LINKS",
  linksFail: "FAILED LINKS",
  cantScan: "CANNOT SCAN",
  totalAnalyzed: "TOTAL ANALYZED",
  executionTime: "EXECUTION TIME",
  createdItem: "Created Item",
  editedItem: "Edited Item",
  deletedItem: "Deleted Item",
  docUpload: "Document uploaded",
  docDelete: "Document deleted",
  docChange: "Document changed",
  newNotification: "New notification",
  newCommentThread: "New comment",
  newCommentThreadInRequest:
    'A new comment has been added to request "{request}".',
  activeProjects: "Active projects",
  closedProjects: "Closed projects",
  removedProjects: "Removed projects",
  readAll: "Read all",
  unknownProject: "Unknown project",
  selectTenant: "Select a tenant",
  tenantId: "Directory (Tenant) ID",
  tenant: "Tenant",
  tenantSelected: "tenant selected | tenants selected",
  directory: "Directory",
  dark: "Dark",
  light: "Light",
  theme: "Theme",
  selectTheme: "Select a theme for the title",
  methodology: "Methodology",
  waterfall: "Waterfall",
  agile: "Agile",
  linkInsert: "Insert a link",
  projectTimeline: "Project & Timeline",
  invalidURL: "Invalid URL",
  filterPhases: "Filter by phase",
  phase: "Phase | Phases",
  scope: "Scopes | Scopes",
  artifact: "Artifact | Artifacts",
  noArtifactsForScope: "There is no artifact uploaded for the selected scope.",
  importantScopesUploaded:
    "There is at least one artifact for every required scope.",
  detailsAndTemplates: "Details and templates",
  addLink: "Add link",
  alreadyUploadedDoc: "The following document was found:",
  docToUpload: "Selected document: ",
  selectScopeToContinue: "Select an scope to continue",
  noArtifactsUploaded: "There are no artifacts uploaded.",
  missingImportantArtifacts: "The following artifacts are required: ",
  importantPendingScope: "required pending scopes | required pending scopes",
  finish: "Finish",
  color: "Colour",
  docPreviewMessage: "This file type is not previewable, please download it.",
  activity: "Activity",
  finishEnter: "Finish & enter",
  quantity: "Quantity",
  billing: "Billing",
  nextBilling: "Next billing: ",
  noItems: "There are no items to show yet.",
  until: "until",
  refundable: "Refundable | refundable",
  autorenewOn: "Autorenew on",
  autorenew: "Autorenew",
  expiresOn: "Expires on",
  expires: "Expires",
  operation: "Operation | operation",
  operationHistory: "Operation history",
  addSubscriptions: "Add subscriptions",
  addToCart: "Add to cart",
  cart: "Cart",
  emptyCart:
    "Your cart is empty.<br>Specify a quantity of any product and add it to the cart to continue.",
  product: "Product",
  reviewOrder: "Review order",
  fullDescription: "Full description",
  showLess: "Show less",
  purchase: "Purchase",
  licencesPurchase: "Licences purchase | licences purchase",
  licencesCancellation: "Licences cancellation | licences cancellation",
  licenceIncrement: "Licence increment",
  cancellation: "Cancellation",
  cancelSubscription: "Cancel subscription",
  autoRenewEnable: "Auto-renewal enabled",
  autoRenewDisable: "Auto-renewal disabled",
  termDuration: "Term duration | term duration",
  billingFrequency: "Billing frequency | billing frequency",
  frequency: "Frequency | frequency",
  changeFriendlyName: "Changed subscription nickname",
  changeDurationBilling: "Change {type}",
  changeTermDuration: "Change term duration",
  changeBillingFrequency: "Change billing frequency",
  purchaseReceipt: "Purchase receipt",
  productsPurchase: "Products purchase",
  confirmationTxt: "Confirmation",
  confirmationIncrement:
    "You are purchasing <b>{quantity}</b> licences of <b>{subscription}</b>, you will have 7 days to ask for a refund.",
  confirmationDecrement:
    "You will cancel <b>{quantity}</b> licences of <b>{subscription}</b>, since those have been purchased less than 7 days ago, you will have a refund.",
  confirmationChangeFriendlyName:
    "Are you sure you want to change the nickname from <b>{oldName}</b> to <b>{newName}</b>?",
  confirmationCancellationNo:
    "All <b style='color: red'>{licences} licence(s)</b> in this product are not refundable, so you <b style='color: red'>cannot cancel them automatically</b>. Please provide the reason and desired date for the cancellation and you will be contacted by our CSP team.",
  confirmationCancellationMixed:
    "<b>{refundableLicences} licence(s)</b> in this product that have been purchased less than 7 days ago will be <b>refunded</b>. Regarding the other <b style='color: red'>{licences} licence(s)</b>, these <b style='color: red'>cannot be cancelled automatically</b>, so please explain the reason of the cancellation and you will be contacted by our CSP team.",
  confirmationCancellationAll:
    "All <b>{refundableLicences} licence(s)</b> in this product have been purchased less than 7 days ago so they will be <b>refunded</b>.",
  confirmationAutoRenewEnable:
    "Are you sure you want to enable the auto-renewal for this product? Please note that the renewal date will be {date}.",
  confirmationAutoRenewDisable:
    "Are you sure you want the auto-renewal to be disabled for this product? Please explain the reason of disabling it and our team will contact you soon.",
  confirmationDurationBilling:
    "Are you sure you want the {type} to be changed for this product? \n Please explain the reason and our team will contact you soon.",
  reason: "Reason",
  subscriptionCancellation: "Subscription cancellation",
  contactWithClient:
    "Please contact with the client to resolve the cancellation request",
  checkConfirmation:
    "I confirm that I want to cancel the licences and be refunded.",
  insertCancellationDate:
    "Introduce the date since when you want to cancel the subscription:",
  reasonPlaceholder: "Explain the reason",
  cancellationDate: "Cancellation date",
  requestDate: "Request date",
  operationDetails: "Operation details",
  endingEmailEnable:
    "The auto renewal for the product above has been enabled. In case of mistake, you can proceed to the CSP Operations extension of your project to revert this action.",
  productType: "Product type",
  endingEmailDisable:
    "The client has asked to disable the auto-renewal for the product above. Please contact with the client to resolve this request.",
  endingDurationBilling:
    "The client has asked to change the {type} for the product details above. Please contact with the client to resolve this request.",
  suspendedToKnowMore:
    "To know more about this status please ask your Sogeti point of contact.",
  refundableLicenceLink:
    '<b>IMPORTANT</b>: The licences just bought will be refundable only for the next 7 days. In that case, you may perform the cancellation operation in the <a href="{link}" target="_blank">CSP Operations extension</a> of your project.',
  newOperation:
    "A new operation has been performed on the SogetiHub CSP Operations extension",
  newOperationByClient:
    "A new operation has been performed on the SogetiHub CSP Operations extension by the client {client}",
  cspStatusActive: "Active",
  cspStatusCancelled: "Cancelled",
  cspStatusSuspended: "Suspended",
  cspStatusExpired: "Expired",
  cspStatusDisabled: "Disabled",
  cspStatusPending: "Pending",
  cspStatusPendingCancellation: "Pending cancellation",
  annual: "Annual",
  monthly: "Monthly",
  weekly: "Weekly",
  daily: "Daily",
  triennial: "Triennial",
  doNotRepeat: "Do not repeat",
  recurrenceEndDate: "Recurrence end date",
  na: "N/A",
  unknown: "Unknown | Unknown",
  pendingOperation: "There are operations in progress",
  unitPrice: "Unit price",
  totalPrice: "Total price",
  searchProduct: "Search a product",
  noTenantForClient: "There are no tenants selected for this client.",
  subscriptionsLoading: "Subscriptions will appear here.",
  noProductsMatchSearch: "No products match your search.",
  cspTypeDescAzure:
    "Azure Reservations allow partners to pre-purchase Azure resources in advance, such as virtual machines, storage, and databases. By committing to a reservation, partners can achieve cost savings and ensure resource availability for their customers.",
  cspTypeDescOnlineServices:
    "Online Services refer to cloud-based software and applications. This category includes products such as Microsoft 365 (formerly Office 365), Dynamics 365, and Azure services.",
  cspTypeDescSoftware:
    "Software category includes a wide range of software options to help businesses enhance their productivity and streamline their workflows, that focus on collaboration, data management, and other aspects of business operations (Microsoft Office suite, Visual Studio, Teams, etc.).",
  employee: "Employee",
  jobPosition: "Job position",
  projectRole: "Project role",
  resolvedRequest: "Resolved request: ",
  closedRequest: "Closed request: ",
  openRequest: "Opened request: ",
  cspAlertSuccess:
    "The operation has been successfully completed. It may take a few minutes for the changes to be visible.",
  cspAlertError:
    "Something has gone wrong while performing the operation. Please try again later or contact with our team.",
  cspAlertInfo: "Operation in progress, please wait a few moments.",
  endingClientGeneral:
    "Please wait for our team to resolve this operation request.",
  emailAlert: "It may take a few minutes for the changes to be visible.",
  refresh: "Refresh",
  loading: "Loading",
  creatingSlowOperation: "Creating, this operation might take some time.",
  methodPhaseProposal: "Proposal",
  methodProposalApproach: "Proposal approach",
  methodCustomerOffer: "Customer offer",
  methodPhaseStarting: "Starting",
  methodPrerequirements: "Prerequirements",
  methodProjectPlan: "Project plan",
  methodTransparencyPlan: "Transparency plan",
  methodKickOff: "Kick-off",
  methodPhaseExecutionDevelopment: "Execution - Development",
  methodRequirementsFunctional: "Functional requirements",
  methodRequirementsNonFunctional: "Non-functional requirements",
  methodRisks: "Risks",
  methodGoodPractices: "Good practices",
  methodEnvironmentsDeployment: "Environments and deployment",
  methodSoftwareAssetsManagement: "Software assets management",
  methodDesignArchitecture: "Design and architecture",
  methodUXUI: "UX/UI",
  methodPhaseExecutionQualityEngineering:
    "Execution - Quality Engineering (QE)",
  methodQEStrategy: "QE Strategy",
  methodReportingMetrics: "Reporting and metrics",
  methodTestingPlan: "Testing plan",
  methodDefectManagement: "Defect management",
  methodTestAutomation: "Test automation",
  methodDataEnvironmentManagement: "Data and environment management",
  methodTestware: "Testware",
  methodPhaseExecutionReportingGovernance: "Execution - Reporting & Governance",
  methodCustomerSatisfactionAcceptance: "Customer satisfaction and acceptance",
  methodRelationshipMonitoringModel: "Relationship and monitoring model",
  methodInternalMonitoring: "Internal monitoring",
  methodPlanningManagement: "Planning management",
  methodKnowledgeManagement: "Knowledge management",
  methodEconomicManagement: "Economic management",
  methodTeamRolesResponsibilities: "Team, roles and responsabilities",
  methodSubcontracting: "Subcontracting",
  methodChangesManagement: "Changes management",
  methodPhaseWarranty: "Warranty",
  methodWarrantyPlanning: "Warranty planning",
  methodWarrantyExecution: "Warranty execution",
  methodPhaseClosing: "Closing",
  methodClosingManagement: "Closing management",
  methodPossibleSuccessCase: "Possible success case",
  onboardingEmailTitle: "Welcome to SogetiHub!",
  onboardingEmailSubtitle: "You have been signed up on the platform",
  projectIncorporationEmailTitle: "You have been added to a project",
  projectIncorporationEmailGreeting:
    "You have just been added to the project <b>{project}</b> on SogetiHub. Below you can see the specific event types and features of your project:",
  projectIncorporationEmailEnding:
    'Access the project from the SogetiHub homepage or by clicking <a href="{link}" target="_blank">here</a>. You can also review the <b>SogetiHub functionalities</b> in this short video:'
};
