










































































































































































  import {
    eventTypes,
    getTimelineItemIcon
  } from "../../../_helpers/eventTypesStates";
  import {
    getExtensionIcon,
    servicesExtensions
  } from "../../../_helpers/servicesExtensions";
  import PeopleCore from "../../../models/core/PeopleCore";
  import EventDx from "../../../models/deliveryexperience/EventDx";
  import ReadWrite from "../../../models/deliveryexperience/Permissions/ReadWrite";
  import RequestDx from "../../../models/deliveryexperience/RequestDx";
  import StageDx from "../../../models/deliveryexperience/StageDx";
  import utils from "../../../utils/utils";
  import PeopleIcon from "../../../view-models/PeopleIcon";
  import PeopleInfo from "../../view_elements/PeopleInfo.vue";
  import AddDeletePeople from "./people/AddDeletePeople.vue";
  import PeoplePermissions from "./people/PeoplePermissions.vue";

  export default {
    name: "PeopleExtension",
    components: {
      PeoplePermissions,
      AddDeletePeople,
      PeopleInfo
    },
    props: {
      projectDx: Object,
      user: PeopleCore,
      peopleDx: Array,
      eventId: String,
      eventsDx: Array,
      stageId: String,
      requestId: String
    },
    data() {
      return {
        teamList: false,
        teamSelected: true,
        extended: false,
        text: {
          author: this.$t("author"),
          members: this.$tc("member", 2),
          projectTeam: this.$t("projectTeam"),
          teamMember: this.$t("teamMember"),
          noTeamMembers: this.$t("noTeamMembers", {
            event: eventTypes.PEOPLE.events.newIncorporation.name
          }),
          eventPeopleTitle: this.$t("eventPeopleTitle"),
          stagePeopleTitle: this.$t("stagePeopleTitle"),
          requestPeopleTitle: this.$t("requestPeopleTitle"),
          peopleTitle:
            servicesExtensions.deliveryExperience.extensions.people.name
        },
        lastProjectVersionDx: String(undefined),
        selectedPeopleIndex: -1,
        deleting: false,
        peopleDeleted: PeopleIcon,
        clicked: false,
        stage: [],
        peopleToSearchCloned: PeopleIcon,
        peopleEdited: []
      };
    },
    computed: {
      projectPeopleDxIcons(): PeopleIcon[] {
        return this.peopleDx.map(people => {
          return PeopleIcon.newPeopleIconFromDx(people);
        });
      },
      itemSelected(): EventDx | StageDx | RequestDx | null {
        if (this.eventId) {
          return this.getEventDx(this.eventId);
        } else if (this.stageId) {
          return this.getStageDx(this.stageId);
        } else if (this.requestId) {
          return this.getRequestDx(this.requestId);
        }
        return null;
      },
      selectedItemAuthor(): PeopleIcon {
        let result = undefined;
        if (this.itemSelected) {
          result = PeopleIcon.newPeopleIconFromCore(
            this.getPeopleByUsername(this.itemSelected.getAuthor)
          );
        }
        return result;
      },
      peopleToShow: function () {
        let people: PeopleIcon[] = [];
        let extendable = false;
        let eventClicked = false;
        // When an event or stage or request is clicked
        // Event
        if (this.eventId !== undefined || this.stageId !== undefined) {
          if (this.eventId !== undefined) {
            people = this.getTimelineItemFilteredPeople(this.eventId, "Event");
            extendable = false;
            // Stage
          } else if (this.stageId !== undefined) {
            people = this.getTimelineItemFilteredPeople(this.stageId, "Stage");
            extendable = false;
          }
          eventClicked = true;
        }
        // If an event/stage is closed
        else if (!this.itemSelected && this.peopleDx !== undefined) {
          eventClicked = false;
          people = this.projectPeopleDxIcons;
          extendable = false;
        }
        people.sort((people1, people2) => {
          const comparedNames = utils.compareWordsAlphabetically(
            people1.getFirstName,
            people2.getFirstName
          );
          return comparedNames == 0
            ? utils.compareWordsAlphabetically(
                people1.getLastName,
                people2.getLastName
              )
            : comparedNames;
        });
        return {
          people: people,
          extendable: extendable,
          eventClicked: eventClicked
        };
      },
      textNoMembers: function (): string | null {
        if (!this.peopleToShow.people.length && this.eventId) {
          return this.$tc("noMembers", 1, {
            entity: this.$tc("event", 2)
          });
        } else if (!this.peopleToShow.people.length && this.stageId) {
          return this.$tc("noMembers", 2, {
            entity: this.$tc("stage", 2)
          });
        } else return null;
      },
      permissionsPeople(): ReadWrite {
        return this.getUserPermissions.people;
      },
      getExtensionIcon(): NodeRequire {
        return getExtensionIcon(
          servicesExtensions.deliveryExperience.extensions.people.name
        );
      }
    },
    methods: {
      getPeopleIcon(username: string): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(
          this.getPeopleByUsername(username)
        );
      },
      getTimelineItemFilteredPeople(id: string, typeId: string): PeopleIcon[] {
        let result: PeopleIcon[] = (
          typeId == "Event"
            ? this.getPeopleDxByEventId(id)
            : this.getPeopleDxByStageId(id)
        ).map(people => {
          return PeopleIcon.newPeopleIconFromDx(people);
        });
        for (let i = 0; i < result.length; i++) {
          if (result[i].getUsername == this.itemSelected.getAuthor) {
            result.splice(i, 1);
          }
        }
        return result;
      },
      getTimelineItemIcon(
        timelineItemType: string,
        color?: string
      ): NodeRequire {
        return getTimelineItemIcon(timelineItemType, color);
      },
      openPeoplePermissions() {
        this.$bvModal.show("permissions-management-modal-people");
      }
    },
    async created() {
      await this.loadPeople();
    }
  };
