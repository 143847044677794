

























  import RadialProgressChart from "@bunetz/radial-progress-chart-with-tooltip";
  import { projectTypes } from "../../../_helpers/projectMetadata";
  import StageDx from "../../../models/deliveryexperience/StageDx";

  export default {
    name: "ProgressBar",
    props: {
      project: Object,
      href: String,
      id: String,
      eventsDx: Array,
      stagesDx: Array
    },
    data() {
      return {
        chart: Object,
        legend: [],
        text: {
          eventsCompleted: this.$t("eventsCompleted"),
          stagesCompleted: this.$t("stagesCompleted"),
          lifetime: this.$t("lifetime")
        }
      };
    },
    mounted() {
      this.createLiabilityRadialProgress();

      // Access the <div> with the class "chart-tooltip"
      const chartTooltipElement = document.querySelector(".chart-tooltip");

      // Access the <p> inside the class "chart-tooltip"
      const tooltipTextElement = chartTooltipElement.querySelector("p");

      //
      var observer = new MutationObserver(function () {
        if ((chartTooltipElement as HTMLElement).style.visibility != "hidden") {
          const nodePercentage = tooltipTextElement.childNodes[2];
          if (nodePercentage) tooltipTextElement.removeChild(nodePercentage);
        }
      });

      // Start observing the tooltip element for mutations
      observer.observe(chartTooltipElement, {
        attributes: true,
        childList: true,
        subtree: true
      });
    },
    methods: {
      async createLiabilityRadialProgress() {
        this.chart = await new RadialProgressChart("#" + this.id, {
          diameter: 200,
          stroke: {
            width: 30,
            gap: 2
          },
          series: this.progressBarSeries(this.project.getProjectType)
        });
      },
      progressBarSeries(projectType) {
        const eventsProgres = this.getItemsCompleted("events");
        const stagesProgres = this.getItemsCompleted("stages");
        let series = [
          {
            title: `${this.text.eventsCompleted} (${eventsProgres.completed}/${eventsProgres.total})`,
            labelStart: "\uF560",
            value: eventsProgres.percentage,
            color: "var(--green)"
          },
          {
            title: `${this.text.stagesCompleted} (${stagesProgres.completed}/${stagesProgres.total})`,
            labelStart: "\uF274",
            value: stagesProgres.percentage,
            color: "var(--bright-purple)"
          }
        ];
        if (projectType != projectTypes.liabilityEconomic.name) {
          series.push({
            title: this.text.lifetime,
            labelStart: "\uF1DA",
            value: this.getLifetimePercentage(),
            color: this.getLifetimeColor()
          });
        }
        this.legend = series;
        return series;
      },
      getCurrentStage: function (): StageDx {
        let currentStage: StageDx = null;
        if (this.project.getId != undefined) {
          for (const stage of this.stagesDx) {
            if (
              new Date(stage.getLastHistoryBaseline.startedDate) < new Date() &&
              new Date(stage.getLastHistoryBaseline.endDate) > new Date()
            )
              currentStage = stage;
          }
        }
        return currentStage;
      },
      getItemsCompleted: function (itemType: string): {
        completed: number;
        total: number;
        percentage: number;
      } {
        let itemsCompleted = 0;
        let itemsTotal = 0;
        if (this.project.getId != undefined) {
          const actualDate = new Date().getTime();
          const items = itemType == "events" ? this.eventsDx : this.stagesDx;
          for (const item of items) {
            ++itemsTotal;
            if (
              (itemType == "events" &&
                new Date(item.getLastHistoryBaseline.startedDate).getTime() <
                  actualDate) ||
              (itemType == "stages" &&
                new Date(item.getLastHistoryBaseline.endDate).getTime() <
                  actualDate)
            ) {
              ++itemsCompleted;
            }
          }
        }
        return {
          completed: itemsCompleted,
          total: itemsTotal,
          percentage:
            itemsTotal > 0 ? Math.round((itemsCompleted / itemsTotal) * 100) : 0
        };
      },
      getLifetimePercentage: function (): number {
        let lifetimePercentage = 0;
        if (this.project.getId != undefined) {
          const startDate = new Date(this.project.getStartDate);
          let endDate = null;
          if (this.project.getEndDate != null) {
            endDate = new Date(this.project.getEndDate);
          } else {
            endDate = new Date(startDate);
            endDate.setFullYear(startDate.getFullYear() + 1);
          }
          const currentDate = new Date();
          if (startDate.getTime() < currentDate.getTime()) {
            lifetimePercentage =
              ((currentDate.getTime() - startDate.getTime()) /
                (endDate.getTime() - startDate.getTime())) *
              100;
          } else {
            lifetimePercentage = 0;
          }
        }
        return Math.round(lifetimePercentage);
      },
      getLifetimeColor: function (): string | null {
        let color = null;
        if (this.project.getId != undefined) {
          if (this.getLifetimePercentage() > 100) {
            color = "rgba(255, 48, 76, 0.8)";
          } else {
            color = "rgba(0, 112, 173, 0.8)";
          }
        }
        return color;
      }
    }
  };
