var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.eventStageRequest != undefined)?_c('b-container',{staticStyle:{"z-index":"3"},attrs:{"fluid":""}},[_c('b-row',{staticClass:"header mt-3"},[_c('b-col',{staticClass:"pl-3",attrs:{"cols":"2"}},[(_vm.isEventOrStage)?[_c('b-img',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
            _vm.type === 'stage'
              ? _vm.text.stage
              : _vm.getEventRequestName(_vm.eventStageRequest.getEventType)
          ),expression:"\n            type === 'stage'\n              ? text.stage\n              : getEventRequestName(eventStageRequest.getEventType)\n          ",modifiers:{"hover":true,"top":true}}],class:{ 'mt-3': _vm.type === 'stage' },attrs:{"width":"40px","height":"auto","src":_vm.getTimelineItemIcon(
              _vm.eventStageRequest.getEventType,
              _vm.eventStageRequest.getColor
            )}}),_c('div',{staticClass:"event-stage-status",class:{
            'status-ocurred':
              _vm.eventStageRequest.getBaselineByVersion(_vm.versionDx).status ==
              _vm.occurredStateString,
            'status-pending-confirm':
              _vm.eventStageRequest.getBaselineByVersion(_vm.versionDx).status ==
              _vm.pendingToConfirmStateString,
            'status-pending-occur':
              _vm.eventStageRequest.getBaselineByVersion(_vm.versionDx).status ==
              _vm.pendingToOccurStateString,
            'status-cancelled':
              _vm.eventStageRequest.getBaselineByVersion(_vm.versionDx).status ==
              _vm.cancelledStateString
          }})]:[_c('b-img',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getEventRequestName(_vm.eventStageRequest.type)),expression:"getEventRequestName(eventStageRequest.type)",modifiers:{"hover":true,"top":true}}],attrs:{"width":"40px","height":"40px","src":_vm.getTimelineItemIcon(_vm.eventStageRequest.getType)}})]],2),_c('b-col',{attrs:{"cols":"10"}},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"ml-2 text-overflow-ellipsis font-weight-bold",class:{ 'text-multiple-lines-max-2': _vm.comeFrom != 'carousel' },staticStyle:{"min-height":"45px","max-height":"45px"}},[_c('p',{class:{ 'text-truncate': _vm.comeFrom == 'carousel' }},[_vm._v(" "+_vm._s(_vm.isEventOrStage ? _vm.eventStageRequest.getName : _vm.eventStageRequest.getIssue)+" ")])]),_c('b-col',{staticClass:"timeline-item-buttons",attrs:{"cols":_vm.editAvailable || !_vm.isEventOrStage ? 4 : 3}},[_c('PeopleDefaultIcon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getItemAuthorName),expression:"getItemAuthorName",modifiers:{"hover":true,"top":true}}],staticClass:"timeline-item-people-icon",attrs:{"people":_vm.eventStageRequestAuthorIcon,"size":20}}),(!_vm.isEventOrStage)?_c('div',{staticClass:"button-hover",staticStyle:{"margin-top":"1px"},on:{"click":function($event){return _vm.openRequest()}}},[_c('FontAwesomeIcon',{staticClass:"timeline-item-option-icon",attrs:{"icon":"external-link-alt"}})],1):_vm._e(),(
              (_vm.editAvailable &&
                _vm.permissionsEvents.write &&
                _vm.type === 'event') ||
              (_vm.editAvailable &&
                _vm.permissionsStages.write &&
                _vm.type === 'stage') ||
              (_vm.editAvailable &&
                _vm.permissionsRequests.write &&
                !_vm.isEventOrStage &&
                (_vm.getUser.isSogeti() ||
                  _vm.getUser.getUsername == _vm.eventStageRequest.getAuthor))
            )?_c('div',{staticClass:"button-hover",on:{"click":function($event){return _vm.openTimelineItemForm()}}},[_c('FontAwesomeIcon',{staticClass:"timeline-item-option-icon",attrs:{"icon":"fa-solid fa-pen"}})],1):_vm._e(),(_vm.showCancel)?_c('div',{staticClass:"button-hover",on:{"click":function($event){return _vm.handleClose($event)}}},[_c('FontAwesomeIcon',{staticClass:"timeline-item-close-icon",attrs:{"icon":"fa-solid fa-xmark"}})],1):_vm._e()],1)],1)],1)],1),_c('section',[_c('div',{staticStyle:{"min-height":"25px","max-width":"300px"}},[(_vm.eventStageRequest.getTag)?_c('b-row',[_c('b-col',{staticClass:"tag-row text-overflow-ellipsis"},[_c('p',{staticClass:"tag-p text-truncate"},[_vm._v(" "+_vm._s(_vm.eventStageRequest.getTag)+" ")])])],1):_vm._e()],1),_c('b-row',{staticClass:"mx-3"},[_c('b-col',[_c('b',[_vm._v(_vm._s(_vm.isEventOrStage ? _vm.text.description : _vm.text.requestDetails))])]),_c('div',{staticClass:"button-hover",on:{"click":_vm.openItemDescriptionModal}},[(_vm.eventStageRequestDescription.length)?_c('FontAwesomeIcon',{attrs:{"icon":"fas fa-expand-alt"}}):_vm._e()],1)],1),_c('b-row',{staticClass:"mx-3 small-scrollbar description-container"},[(_vm.eventStageRequestDescription.length)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.eventStageRequestDescription)}}):_c('div',{staticClass:"info-text text-center"},[_vm._v(" "+_vm._s(_vm.$t("noTimelineItemDescription"))+" ")])])],1),(_vm.isEventOrStage)?[_c('b-row',{staticClass:"mx-3"},[_c('b-col',{attrs:{"cols":"7"}},[_c('b',[_vm._v(_vm._s(_vm.$t("affected"))+":")]),(_vm.itemAffected && _vm.itemAffected.length != 0)?_c('b-img',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.itemAffected.getName),expression:"itemAffected.getName",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",attrs:{"width":"20px","height":"auto","src":_vm.getTimelineItemIcon(
              _vm.type === 'event' ? _vm.itemAffected.getEventType : 'stage',
              _vm.itemAffected.getColor
            )},on:{"click":function($event){return _vm.openAffectedRelatedPredecessor($event, _vm.itemAffected)}}}):_c('span',{staticClass:"ml-2"},[_vm._v(" - ")])],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b',[_vm._v(_vm._s(_vm.$t("predecessor"))+":")]),(_vm.itemPredecessor && _vm.itemPredecessor.length != 0)?_c('b-img',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.itemPredecessor.getName),expression:"itemPredecessor.getName",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",attrs:{"width":"20px","height":"auto","src":_vm.getTimelineItemIcon(
              _vm.type === 'event' ? _vm.itemPredecessor.getEventType : 'stage',
              _vm.itemPredecessor.getColor
            )},on:{"click":function($event){return _vm.openAffectedRelatedPredecessor($event, _vm.itemPredecessor)}}}):_c('span',{staticClass:"ml-2"},[_vm._v(" - ")])],1)],1),_c('b-row',{staticClass:"mx-3 mt-3 start-related"},[_c('b-col',{attrs:{"cols":"7"}},[_c('FontAwesomeIcon',{staticStyle:{"height":"16","color":"var(--dark-gray-25)","margin-right":"5px"},attrs:{"icon":"fa-solid fa-calendar-days"}}),(_vm.type === 'event')?_c('span',[_vm._v(" "+_vm._s(_vm.getStartedDate)+" ")]):_c('span',[_c('b',[_vm._v(_vm._s(_vm.text.start))]),_vm._v(": "+_vm._s(_vm.getStartedDate)+" ")])],1),(_vm.type === 'event')?_c('b-col',{attrs:{"cols":"5"}},[_c('b',[_vm._v(_vm._s(_vm.$t("related"))+":")]),(_vm.eventRequestRelated)?_c('b-img',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getRelatedEventOrRequestTooltip()),expression:"getRelatedEventOrRequestTooltip()",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",attrs:{"width":"20px","height":"auto","src":_vm.getTimelineItemIcon(
              _vm.eventRequestRelated.getEventType
                ? _vm.eventRequestRelated.getEventType
                : _vm.eventRequestRelated.getType
            )},on:{"click":function($event){return _vm.openAffectedRelatedPredecessor($event, _vm.eventRequestRelated)}}}):_c('span',{staticClass:"ml-2"},[_vm._v(" - ")])],1):_c('b-col',{attrs:{"cols":"5"}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.text.end)+":")]),_vm._v(" "+_vm._s(_vm.getEndDate)+" ")])])],1)]:[_c('b-row',{staticClass:"mx-3"},[_c('b',[_vm._v(_vm._s(_vm.text.type)+": ")]),_vm._v(" "+_vm._s(_vm.getRequestTypeNameTranslated(this.eventStageRequest.getType))+" ")]),_c('b-row',{staticClass:"mx-3"},[_c('b-col',[_c('FontAwesomeIcon',{staticStyle:{"height":"16","color":"var(--dark-gray-25)"},attrs:{"icon":"fa-solid fa-calendar-days"}}),_c('span',[_vm._v(" "+_vm._s(_vm.getStartedDate)+" ")])],1)],1)],_c('b-modal',{staticStyle:{"height":"80vh"},attrs:{"id":_vm.timelineItemDescriptionId,"size":"lg","hide-footer":"","scrollable":"","centered":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [(_vm.type !== 'stage')?_c('b-img',{staticClass:"mr-2",attrs:{"width":"30px","height":"auto","src":_vm.getTimelineItemIcon(
            _vm.type === 'request'
              ? _vm.eventStageRequest.getType
              : _vm.eventStageRequest.getEventType
          )}}):_c('b-img-lazy',{attrs:{"width":"30px","src":_vm.getTimelineItemIcon(undefined, _vm.eventStageRequest.getColor)}}),_c('b',[_vm._v(" "+_vm._s(_vm.type === "request" ? _vm.eventStageRequest.getIssue : _vm.eventStageRequest.getName)+" ")])]},proxy:true},{key:"modal-header-close",fn:function(){return [_c('div',{staticClass:"button-hover"},[_c('FontAwesomeIcon',{staticStyle:{"height":"24"},attrs:{"icon":"fa-solid fa-xmark"}})],1)]},proxy:true}],null,false,3144059094)},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.eventStageRequestDescription)}})])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }