

























































































































































































































































































































  import {
    eventStates,
    eventTypes,
    getEventName,
    getTimelineItemIcon
  } from "../../../../_helpers/eventTypesStates";
  import helpers from "../../../../_helpers/helperFunctions";
  import { projectTypes } from "../../../../_helpers/projectMetadata";
  import { requestStates } from "../../../../_helpers/requestStates";
  import { servicesExtensions } from "../../../../_helpers/servicesExtensions";
  import EventDx from "../../../../models/deliveryexperience/EventDx";
  import PeopleDx from "../../../../models/deliveryexperience/PeopleDx";
  import StageDx from "../../../../models/deliveryexperience/StageDx";
  import PeopleIcon from "../../../../view-models/PeopleIcon";
  import SingleSelectType from "../../../../view-models/SingleSelectType";
  import PeopleDefaultIcon from "../../../view_elements/PeopleDefaultIcon.vue";

  export default {
    components: { PeopleDefaultIcon },
    name: "TimelineItem",
    props: {
      projectId: Number,
      comeFrom: String,
      eventStageRequest: Object,
      versionDx: Number,
      type: String,
      projectType: String,
      affecteds: Array,
      predecessors: Array,
      showCancel: Boolean
    },
    data() {
      return {
        timelineItemFormData: {
          projectId: this.projectId,
          type: undefined,
          action: "edit_popup",
          itemId: undefined
        },
        status: "",
        cancelled: false,
        edit: false,
        description: String(""),
        occurredStateString: eventStates.occurred.name,
        pendingToConfirmStateString: eventStates.pendingToConfirm.name,
        pendingToOccurStateString: eventStates.pendingToOccur.name,
        cancelledStateString: eventStates.cancelled.name,
        virtualVisitEventString:
          eventTypes.REQUEST.events.virtualVisitRequest.name,
        eventStage: new SingleSelectType({
          key: undefined,
          name: ""
        }),
        permissionsRequests: {},
        permissionsEvents: {},
        permissionsStages: {},
        text: {
          description: this.$t("description"),
          generateNotes: this.$t("generateNotes"),
          start: this.$t("start"),
          end: this.$t("end"),
          requestDetails: this.$t("requestDetails"),
          type: this.$t("type"),
          exMember: this.$t("exMember"),
          stage: this.$tc("stage", 1)
        }
      };
    },
    mounted: function () {
      this.permissionsRequests = this.$parent.permissionsRequests;
      this.permissionsEvents = this.$parent.permissionsEvents;
      this.permissionsStages = this.$parent.permissionsStages;
    },
    computed: {
      timelineItemDescriptionId: function (): string {
        return (
          this.comeFrom +
          "-timeline-item-description-" +
          this.eventStageRequest.getId
        );
      },
      peopleDxByProjectId: function (): PeopleDx[] {
        return this.getPeopleDxByProjectId(this.projectId);
      },
      eventStageRequestAuthorIcon: function (): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(
          this.getPeopleByUsername(this.eventStageRequest.getAuthor)
        );
      },
      itemAffected(): EventDx | StageDx {
        const affectedId = this.eventStageRequest.getBaselineByVersion(
          this.versionDx
        ).affecteds[0];

        return this.type === "event"
          ? this.getEventDx(affectedId)
          : this.getStageDx(affectedId);
      },
      itemPredecessor(): EventDx | StageDx {
        const predecessorId = this.eventStageRequest.getBaselineByVersion(
          this.versionDx
        ).predecessors[0];

        return this.type === "event"
          ? this.getEventDx(predecessorId)
          : this.getStageDx(predecessorId);
      },
      eventRequestRelated(): EventDx[] | StageDx[] {
        let related = this.getEventsDxByProjectId(this.projectId).find(
          event => event.getId == this.eventStageRequest.getRelated
        );

        if (!related) {
          related = this.getRequestsDxByProjectId(this.projectId).find(
            request => request.getId == this.eventStageRequest.getRelated
          );
        }
        return related;
      },
      eventTypeMatchRequeriments(): boolean {
        if (this.type === "event") {
          if (
            (this.eventStageRequest.getEventType ==
              eventTypes.COMMON.events.kickOff.type ||
              this.eventStageRequest.getEventType ==
                eventTypes.COMMON.events.followUp.type ||
              this.eventStageRequest.getEventType ==
                eventTypes.COMMON.events.closing.type) &&
            (this.eventStageRequest.getBaselineByVersion(this.versionDx)
              .status == eventStates.pendingToConfirm.name ||
              this.eventStageRequest.getBaselineByVersion(this.versionDx)
                .status == eventStates.occurred.name) &&
            this.eventStageRequest.getReported == false
          ) {
            return true;
          }
        }
        return false;
      },
      eventStageRequestDescription(): string {
        let description = "";
        if (this.type === "event") {
          let i18nCapacity;
          let i18nTAM;
          switch (this.eventStageRequest.getEventType) {
            case eventTypes[projectTypes.capacity.name].events.reload.type:
              i18nCapacity = this.$t("reloaded");
              break;
            case eventTypes[projectTypes.capacity.name].events.consume.type:
              i18nCapacity = this.$tc("consumed", 2);
              break;
            case eventTypes[projectTypes.capacity.name].events.billing.type:
              i18nCapacity = this.$t("billed");
              break;
            case eventTypes.PEOPLE.events.newIncorporation.type:
              i18nTAM = this.$t("newIncorporationDesc");
              break;
            case eventTypes.PEOPLE.events.unassignment.type:
              i18nTAM = this.$t("unassignmentDesc");
              break;
          }
          if (i18nCapacity) {
            description =
              this.$t("amount") +
              " " +
              i18nCapacity +
              ": " +
              this.eventStageRequest.getAmount +
              " " +
              this.getAmountEventUnit +
              "<br>";
          }

          if (i18nTAM) {
            description =
              i18nTAM +
              this.getPeopleByUsername(
                this.eventStageRequest.getTamUser
              ).getNaturalFullName();
          }
        }
        return description + this.eventStageRequest.getDescription;
      },
      getAmountEventUnit(): string | null {
        if (this.projectType == projectTypes.capacity.name) {
          const capacityProject = this.getProjectById(this.projectId);
          return this.getCapacityTypeById(capacityProject.getCapacityTypeId)
            .getName;
        }
        if (
          this.projectType == projectTypes.liabilityFull.name ||
          this.projectType == projectTypes.liabilityEconomic.name
        ) {
          return "€";
        }
        return null;
      },
      editAvailable(): boolean {
        if (
          (this.comeFrom == "timeline" || this.comeFrom == "carousel") &&
          this.eventStageRequest
        ) {
          if (
            this.eventStageRequest.getStatus &&
            this.eventStageRequest.getStatus == requestStates.closed.name
          ) {
            return false;
          }
          if (
            this.eventStageRequest.getEventType &&
            this.eventStageRequest.getEventType ==
              eventTypes[projectTypes.CSP.name].events.cspOperation.type
          ) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      getStartedDate(): string {
        let startDate: Date;
        if (this.isEventOrStage) {
          startDate = new Date(
            this.eventStageRequest.getBaselineByVersion(
              this.versionDx
            ).startedDate
          );
        } else startDate = new Date(this.eventStageRequest.getDate);
        return this.$moment(startDate).format("L");
      },
      getEndDate(): string {
        if (this.type === "stage") {
          const endDate: Date = new Date(
            this.eventStageRequest.getBaselineByVersion(this.versionDx).endDate
          );
          return this.$moment(endDate).format("L");
        } else return "--/--/----";
      },
      isEventOrStage(): boolean {
        return this.type === "event" || this.type === "stage";
      }
    },
    methods: {
      openTimelineItemForm: function () {
        this.timelineItemFormData.itemId = this.eventStageRequest.getId;
        this.timelineItemFormData.type = this.type;
        if (this.isEventOrStage)
          this.timelineItemFormData.action = "edit_popup";
        else {
          this.timelineItemFormData.action = "edit";
        }
        this.$root.$emit("editTimelineItemForm", this.timelineItemFormData);
      },
      openAffectedRelatedPredecessor(e, item) {
        e.stopPropagation();
        if (item.hasOwnProperty("type")) {
          this.$router.push(
            `/${servicesExtensions.deliveryExperience.url}/${this.projectId}/${servicesExtensions.deliveryExperience.extensions.requests.url}/${item.id}`
          );
        } else {
          if (this.comeFrom == "carousel") {
            this.$parent.selectItem(
              item.id,
              this.$parent.getStageEventRequestType(item.id)
            );
          } else {
            this.$parent.openTimelineItem(item.id, this.type);
          }
        }
      },
      openRequest: function () {
        if (!this.isEventOrStage) {
          this.$router.push(
            `/${servicesExtensions.deliveryExperience.url}/${this.projectId}/${servicesExtensions.deliveryExperience.extensions.requests.url}/${this.eventStageRequest.getId}`
          );
        }
      },
      openItemDescriptionModal: function () {
        // Showing the modal after the modal is rendered after
        // the timeline item data v-if switches
        this.$nextTick(() =>
          this.$bvModal.show(this.timelineItemDescriptionId)
        );
      },
      getItemAuthorName: function (): string {
        const isAuthorInProject =
          this.peopleDxByProjectId.find(
            p => p.userName == this.eventStageRequest.getAuthor
          ) != undefined;
        const authorString = helpers.getUserFullName(
          this.eventStageRequestAuthorIcon.getFirstName,
          this.eventStageRequestAuthorIcon.getLastName
        );
        if (authorString.includes("@") || isAuthorInProject) {
          // Can be either email (external user) or full name (user in DX project)
          return authorString;
        } else {
          return `${this.text.exMember} ` + authorString;
        }
      },
      getDate(typeDate: String): Date {
        if (this.eventStageRequest) {
          let date;
          if (typeDate == "end") {
            date = this.eventStageRequest
              .getBaselineByVersion(this.versionDx)
              .endDate.split("T")[0];
          } else if (typeDate == "start") {
            date = this.eventStageRequest
              .getBaselineByVersion(this.versionDx)
              .startedDate.split("T")[0];
          }
          return new Date(date);
        } else return null;
      },
      getEventRequestName(eventType): string {
        return getEventName(eventType);
      },
      getTimelineItemIcon(
        timelineItemType: string,
        color?: string
      ): NodeRequire {
        return getTimelineItemIcon(timelineItemType, color);
      },
      getRelatedEventOrRequestTooltip(): string {
        if (this.eventRequestRelated instanceof EventDx) {
          return this.eventRequestRelated.getName;
        } else {
          return this.eventRequestRelated.getIssue;
        }
      },
      getRequestTypeNameTranslated(requestType): string {
        switch (requestType) {
          case eventTypes.REQUEST.events.serviceRequest.type:
            return this.$t(eventTypes.REQUEST.events.serviceRequest.i18n);
          case eventTypes.REQUEST.events.questionRequest.type:
            return this.$t(eventTypes.REQUEST.events.questionRequest.i18n);
          case eventTypes.REQUEST.events.virtualVisitRequest.type:
            return this.$t(eventTypes.REQUEST.events.virtualVisitRequest.i18n);
          case eventTypes.REQUEST.events.cspRequest.type:
            return this.$t(eventTypes.REQUEST.events.cspRequest.i18n);
          default:
            return requestType;
        }
      },
      handleClose(event) {
        this.$emit("handleClose", event);
      },
      gotToGenerateNotes() {
        this.$router.push(
          "/new-report/" + this.projectId + "/" + this.eventStageRequest.getId
        );
      }
    }
  };
