import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { IBaseLineDx } from "../../../models/deliveryexperience/BaseLineDx";
import EventDx from "../../../models/deliveryexperience/EventDx";
import eventServiceDx from "../../../services/deliveryexperience/eventService";
import { RootState } from "../../index";

interface IEventState {
  eventsDx: EventDx[];
}

const namespaced = true;

const state: IEventState = {
  eventsDx: []
};

const getters: GetterTree<IEventState, RootState> = {
  getEventDx:
    state =>
    (eventId: string): EventDx => {
      return state.eventsDx.find(event => event.getId == eventId);
    },
  getEventsDxByProjectId:
    state =>
    (projectId: number): EventDx[] => {
      return state.eventsDx.filter(event => event.getProjectId == projectId);
    },
  getEventsDxByRelated:
    state =>
    (itemId: string): EventDx[] => {
      return state.eventsDx.filter(event => event.getRelated == itemId);
    },
  getEventsByRecurrenceId:
    state =>
    (recurrenceId: string): EventDx[] => {
      return state.eventsDx.filter(
        event => event.getRecurrenceId == recurrenceId
      );
    }
};

const mutations: MutationTree<IEventState> = {
  setEventsDx(state, events: EventDx[]): void {
    events.forEach(e => {
      const index = state.eventsDx.findIndex(event => event.getId === e.getId);
      if (index === -1) state.eventsDx.push(e);
      else state.eventsDx[index] = e;
    });
  },
  updateEventDx(state, updatedEventDx: EventDx): void {
    const index = state.eventsDx.findIndex(
      e => e.getId === updatedEventDx.getId
    );
    if (index != -1) {
      state.eventsDx.splice(index, 1, updatedEventDx);
    }
  },
  addEventDx(state, event: EventDx): void {
    state.eventsDx.push(event);
  },
  deleteEventDx(state, eventId: string): void {
    const index = state.eventsDx.findIndex(event => event.getId == eventId);
    state.eventsDx.splice(index, 1);
  }
};

const actions: ActionTree<IEventState, RootState> = {
  async loadEventsDx({ commit }): Promise<any> {
    const events: EventDx[] = await eventServiceDx.methods.getEvents();
    commit("setEventsDx", events);
  },
  async loadEventDx({}, eventId: string): Promise<any> {
    const event: EventDx = await eventServiceDx.methods.getEvent(eventId);
    return event;
  },
  async loadEventsDxByProjectId({ commit }, projectId: number): Promise<any> {
    const events: EventDx[] = await eventServiceDx.methods.getEventsByProjectId(
      projectId
    );
    commit("setEventsDx", events);
  },
  async postEventDx({ commit }, newEvent: EventDx): Promise<string> {
    const event: EventDx = await eventServiceDx.methods.postEvent(newEvent);
    commit("addEventDx", event);
    return event.getId;
  },
  async putEventDx({ commit }, modifiedEvent: EventDx): Promise<any> {
    const event: EventDx = await eventServiceDx.methods.putEvent(modifiedEvent);
    commit("updateEventDx", event);
  },
  async putEventDxBaseline(
    { commit },
    data: { eventId: string; baseline: IBaseLineDx }
  ): Promise<any> {
    const eventDx: EventDx = await eventServiceDx.methods.putBaseline(data);
    commit("updateEventDx", eventDx);
  },
  async postEventDxBaseline(
    { commit },
    data: { eventId: string; baseline: IBaseLineDx }
  ): Promise<any> {
    const eventDx: EventDx = await eventServiceDx.methods.postBaseline(data);
    commit("updateEventDx", eventDx);
  },
  async deleteEventDx(
    { commit, dispatch, getters, rootGetters },
    eventId: string
  ): Promise<any> {
    await eventServiceDx.methods.deleteEvent(eventId);
    const deletedEvent = getters.getEventDx(eventId);
    commit("deleteEventDx", eventId);

    /* Cascade */
    for (const people of rootGetters["peopleDx/getPeopleDxByEventId"](
      eventId
    )) {
      const index = people.getEvents.indexOf(eventId);
      people.setEvents = people.getEvents.splice(index, 1);
      await dispatch("peopleDx/updatePeopleDxLocal", people, { root: true });
    }

    for (const document of rootGetters["documentDx/getDocumentsDxByEventId"](
      eventId
    )) {
      await dispatch("documentDx/deleteDocumentDxLocal", document.getId, {
        root: true
      });
    }

    if (deletedEvent.getTag) {
      await dispatch(
        "tagDx/checkRemainingProjectTag",
        { tag: deletedEvent.getTag, projectId: deletedEvent.getProjectId },
        { root: true }
      );
    }
  },
  updateEventDxLocal({ commit }, modifiedEvent: EventDx): void {
    commit("updateEventDx", modifiedEvent);
  },
  deleteEventDxLocal({ commit }, eventId: string): void {
    commit("deleteEventDx", eventId);
  }
};

export const eventModuleDx: Module<IEventState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
