























  import helpers from "../../_helpers/helperFunctions";
  import utils from "../../utils/utils";
  import PeopleIcon from "../../view-models/PeopleIcon";
  import PeopleDefaultIcon from "./PeopleDefaultIcon.vue";
  import ShowAllItems from "./ShowAllItems.vue";

  export default {
    name: "PeopleIcons",
    components: { PeopleDefaultIcon, ShowAllItems },
    data() {
      return {};
    },
    props: {
      people: Array, //Array of PeopleIcon Class
      id: {
        type: String,
        default: "timeline"
      },
      minimumShowedPeople: {
        type: Number,
        default: 3
      }
    },
    computed: {
      shownPeople: function (): {
        result: PeopleIcon[];
        extraPeople: number;
        extraP: boolean;
      } {
        let result: PeopleIcon[] = [];
        let extraPeople: number = 0;
        let hasExtraPeople: boolean = false;
        if (this.people != undefined || this.people.length != 0) {
          let peopleSorted: PeopleIcon[] = this.people;
          peopleSorted.sort((people1, people2) => {
            const comparedNames = utils.compareWordsAlphabetically(
              people1.getFirstName,
              people2.getFirstName
            );
            return comparedNames == 0
              ? utils.compareWordsAlphabetically(
                  people1.getLastName,
                  people2.getLastName
                )
              : comparedNames;
          });
          for (const people of peopleSorted) {
            if (result.length < this.minimumShowedPeople) {
              result.push(people);
            } else {
              hasExtraPeople = true;
              ++extraPeople;
            }
          }
        }
        return {
          result: result,
          extraPeople: extraPeople,
          extraP: hasExtraPeople
        };
      }
    },
    methods: {
      getPersonFullName(firstName, lastName): string {
        return helpers.getUserFullName(firstName, lastName);
      }
    }
  };
