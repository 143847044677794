function jsonConcat(o1, o2) {
  return { ...o1, ...o2 };
}

function newGUID(): string {
  return crypto.randomUUID();
}

function isValidUrl(url: string): boolean {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
}

function compareWordsAlphabetically(wordA: string, wordB: string) {
  const normalizedWordA = wordA
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const normalizedWordB = wordB
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return normalizedWordA < normalizedWordB
    ? -1
    : normalizedWordA == normalizedWordB
    ? 0
    : 1;
}

function getContentType(fileNameWithExtension: string, downloadFile?: boolean) {
  const extension = fileNameWithExtension.split(".").pop().toLowerCase();
  switch (extension) {
    case "pdf":
      return "application/pdf";
    case "doc":
      return "application/msword";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "odt":
      return "application/vnd.oasis.opendocument.text";
    case "xls":
      return "application/vnd.ms-excel";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "xlsm":
      return "application/vnd.ms-excel.sheet.macroEnabled.12";
    case "ppt":
      return "application/vnd.ms-powerpoint";
    case "pptx":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "txt":
      return "text/plain";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "zip":
      return "application/zip";
    case "rar":
      return "application/x-rar-compressed";
    case "gz":
      return "application/gzip";
    case "bz":
      return "application/x-bzip";
    case "7z":
      return "application/x-7z-compressed";
    case "mpp":
    case "mpt":
      return "application/vnd.ms-project";
    default:
      if (downloadFile) {
        return "application/octet-stream";
      } else {
        return "undefined";
      }
  }
}

export default {
  jsonConcat,
  newGUID,
  isValidUrl,
  getContentType,
  compareWordsAlphabetically
};
