













































































  import Bookmarks from "../../../components/services/deliveryexperience/Bookmarks.vue";
  import Docs from "../../../components/services/deliveryexperience/Docs.vue";
  import Overview from "../../../components/services/deliveryexperience/Overview.vue";
  import PeopleExtension from "../../../components/services/deliveryexperience/PeopleExtension.vue";
  import Requests from "../../../components/services/deliveryexperience/Requests.vue";
  import Timeline from "../../../components/services/deliveryexperience/Timeline.vue";
  import EventDx from "../../../models/deliveryexperience/EventDx";
  import PeopleDx from "../../../models/deliveryexperience/PeopleDx";
  import ProjectDx from "../../../models/deliveryexperience/ProjectDx";
  import RequestDx from "../../../models/deliveryexperience/RequestDx";
  import StageDx from "../../../models/deliveryexperience/StageDx";
  import { servicesExtensions } from "../../../_helpers/servicesExtensions";

  export default {
    name: servicesExtensions.deliveryExperience.name
      .toLowerCase()
      .replace(/\s+/g, ""),
    components: {
      Timeline,
      PeopleExtension,
      Docs,
      Overview,
      Bookmarks,
      Requests
    },
    data() {
      return {
        loadedData: false,
        eventId: undefined,
        stageId: undefined,
        requestId: undefined,
        componentOverviewExtensionKey: 0,
        currentVersionDxId: undefined as Number,
        text: {
          progress: this.$tc("progress", 1)
        },
        selectedProjectDx: undefined as ProjectDx,
        projectCore: undefined,
        currentProjectId: Number(this.$route.params.projectId)
      };
    },
    async created() {
      this.registerEventStageRequestListeners();
      this.addLoadingComponent(this.$options.name);
      this.currentVersionDxId = await this.fetchLastProjectVersionDx(
        this.currentProjectId
      );
      await this.loadProjectDx(this.currentProjectId);
      this.selectedProjectDx = this.getProjectDx(this.currentProjectId);
      this.projectCore = await this.getProjectById(this.currentProjectId);
      this.lastProjectVersion = await this.fetchLastProjectVersionDx(
        this.currentProjectId
      );
      // await this.setPendingToConfirmEventsStatus();
      // await this.setPendingToConfirmStagesStatus();
      // this.setEventStageRequest();
      this.rerenderOverview();
      this.loadedData = true;
      this.removeLoadingComponent(this.$options.name);
    },
    async mounted() {
      this.$emit(
        "projectHeaderService",
        servicesExtensions.deliveryExperience.name
      );
      this.$emit(
        "projectHeaderExtension",
        servicesExtensions.deliveryExperience.extensions.dxHome.name
      );
    },
    computed: {
      selectedProjectEventsDx(): EventDx[] {
        return this.getEventsDxByProjectId(this.currentProjectId);
      },
      selectedProjectStagesDx(): StageDx[] {
        return this.getStagesDxByProjectId(this.currentProjectId);
      },
      selectedProjectRequestsDx(): RequestDx[] {
        return this.getRequestsDxByProjectId(this.currentProjectId);
      },
      selectedProjectPeopleDx(): PeopleDx[] {
        return this.getPeopleDxByProjectId(this.currentProjectId);
      }
    },
    methods: {
      registerEventStageRequestListeners: function () {
        this.$root.$on("emitEventId", id => {
          this.eventId = id;
        });
        this.$root.$on("emitStageId", id => {
          this.stageId = id;
        });
        this.$root.$on("emitRequestId", id => {
          this.requestId = id;
        });
      },
      rerenderOverview(): void {
        this.componentOverviewExtensionKey++;
      },
      updateTimelineEventsAndOverview(): void {
        this.$root.$emit("updateTimeline", "events");
        this.componentOverviewExtensionKey++;
      }
    }
  };
