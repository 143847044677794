import { IBaseLineDx } from "./BaseLineDx";

export interface IEventDx {
  id?: string;
  recurrenceId?: string;
  name: string;
  description: string;
  amount?: number;
  createdDate: Date;
  confirmed: boolean;
  reported: boolean;
  eventType: string;
  baseline?: IBaseLineDx;
  historyBaselines?: IBaseLineDx[];
  projectId: number;
  occurred: boolean;
  author: string;
  related?: string;
  tag?: string;
  notificationsPolicy?: string;
  tamUser?: string;
}

export default class EventDx {
  private id?: string;
  private recurrenceId?: string;
  private name: string;
  private description: string;
  private amount?: number;
  private createdDate: Date;
  private confirmed: boolean;
  private reported: boolean;
  private eventType: string;
  private baseline?: IBaseLineDx;
  private historyBaselines?: IBaseLineDx[] = [];
  private projectId: number;
  private occurred: boolean;
  private author: string;
  private related?: string;
  private tag?: string;
  private notificationsPolicy?: string;
  private tamUser?: string;

  constructor(dto: IEventDx) {
    Object.assign(this, dto);
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }

  get getRecurrenceId(): string {
    return this.recurrenceId;
  }

  set setRecurrenceId(value: string) {
    this.recurrenceId = value;
  }

  get getName(): string {
    return this.name;
  }

  set setName(value: string) {
    this.name = value;
  }

  get getDescription(): string {
    return this.description;
  }

  set setDescription(value: string) {
    this.description = value;
  }

  get getAmount(): number {
    return this.amount;
  }

  set setAmount(value: number) {
    this.amount = value;
  }

  get getCreatedDate(): Date {
    return this.createdDate;
  }

  set setCreatedDate(value: Date) {
    this.createdDate = value;
  }

  get getConfirmed(): boolean {
    return this.confirmed;
  }

  set setConfirmed(value: boolean) {
    this.confirmed = value;
  }

  get getReported(): boolean {
    return this.reported;
  }

  set setReported(value: boolean) {
    this.reported = value;
  }

  get getEventType(): string {
    return this.eventType;
  }

  set setEventType(value: string) {
    this.eventType = value;
  }

  get getBaseline(): IBaseLineDx {
    return this.baseline;
  }

  set setBaseline(value: IBaseLineDx) {
    this.baseline = value;
  }

  get getHistoryBaselines(): IBaseLineDx[] {
    return this.historyBaselines;
  }

  set setHistoryBaselines(value: IBaseLineDx[]) {
    this.historyBaselines = value;
  }

  get getLastHistoryBaseline(): IBaseLineDx {
    return this.historyBaselines.reduce((max, baseline) =>
      max.version > baseline.version ? max : baseline
    );
  }

  /*
  Return baseline By version:
    - If required version is in history, then return it.
    - If required version is not in history:
        - If last version in history is SMALLER than required version, then return last baseline in history.
        - If last version in history is BIGGER than required version:
            - If first version in history is BIGGER than required version, means event was created after required version, then return NULL.
            - If first version in history is SMALLER than required version, means required version is not in history but its in between
              versions, then return the NEAREST SMALLER version.
  */
  getBaselineByVersion(version: number): IBaseLineDx {
    const baseline: IBaseLineDx = this.historyBaselines.find(
      bl => bl.version == version
    );
    if (baseline) return baseline;
    else {
      const versionToInteger: number = version;
      const lastBaseline: IBaseLineDx = this.getLastHistoryBaseline;
      if (versionToInteger > lastBaseline.version) {
        return lastBaseline;
      } else {
        if (versionToInteger > this.historyBaselines[0].version) {
          let nearestSmallerBaseline: IBaseLineDx = null;
          for (let i = 0; i < this.historyBaselines.length; i++) {
            if (this.historyBaselines[i].version > versionToInteger) {
              nearestSmallerBaseline = this.historyBaselines[i - 1];
              break;
            }
          }
          return nearestSmallerBaseline;
        } else return null;
      }
    }
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }

  get getOccurred(): boolean {
    return this.occurred;
  }

  set setOccured(value: boolean) {
    this.occurred = value;
  }

  get getAuthor(): string {
    return this.author;
  }

  set setAuthor(value: string) {
    this.author = value;
  }

  get getRelated(): string {
    return this.related;
  }

  set setRelated(value: string) {
    this.related = value;
  }

  get getTag(): string {
    return this.tag;
  }

  set setTag(value: string) {
    this.tag = value;
  }

  get getNotificationsPolicy(): string {
    return this.notificationsPolicy;
  }

  set setNotificationsPolicy(value: string) {
    this.notificationsPolicy = value;
  }

  get getTamUser(): string {
    return this.tamUser;
  }

  set setTamUser(value: string) {
    this.tamUser = value;
  }
}
