































  import { projectStates } from "../../_helpers/projectMetadata";
  import ServiceNavbarExtensions from "../../components/services/ServiceNavbarExtensions.vue";
  import ProjectCore from "../../models/core/ProjectCore";
  import ProjectPeopleCore from "../../models/core/project/ProjectPeopleCore";
  import ProjectStateCore from "../../models/core/project/ProjectStateCore";
  import ProjectHeader from "./project-header.vue";

  export default {
    name: "service",
    components: {
      ServiceNavbarExtensions,
      ProjectHeader
    },
    data() {
      return {
        serviceName: "",
        extensionName: "",
        loadedData: false,
        loadedDataNavbar: false,
        expandNavbar: false,
        projectId: null
      };
    },
    created: async function () {
      window.scrollTo(0, 0);
      document.getElementById("spinner").style.top = "50%";
      this.projectId = Number(this.$route.params.projectId);
      this.addLoadingComponent(this.$options.name);
      await this.loadProjectsPeopleCoreByUsername(this.getUser.getUsername);
      await this.loadProjectsCore();
      await this.loadProjectStates();
      await this.loadPeopleDx();
      await this.loadProjectDx(this.projectId);
      await this.loadCatalog();
      await this.loadActiveServicesByClientId(this.getUser.getClientId);
      await this.loadExtensions();
      await this.loadExtensionService();
      await this.loadClients();
      await this.loadPeoplesCoreByClientIdAndSogeti(
        this.getProjectById(this.projectId).getClientId
      );
      this.loadedDataNavbar = true;
      await this.loadCapacityTypes();
      await this.loadVersionsDx();
      await this.loadEventsDxByProjectId(this.projectId);
      await this.loadStagesDxByProjectId(this.projectId);
      await this.loadRequestsDxByProjectId(this.projectId);
      await this.loadTagsDx(this.projectId);
      await this.loadTeamsDx();
      await this.loadPeopleDxInCurrentProject(this.$route.params.projectId);
      this.$root.$emit("rerenderOverview");
      this.loadedData = true;
      this.removeLoadingComponent(this.$options.name);
      this.$root.$on("expandNavbar", value => (this.expandNavbar = value));
    },
    updated: async function () {
      // To tell App component to hide page header and use the header defined here for the navbar
      await this.$root.$emit("inServicePage", true);
    },
    computed: {
      projectsToDisplay(): ProjectCore[] {
        // Fill projects to display
        let projectsOfUsername: ProjectCore[] = [];
        const peopleProjectsByUser = this.getProjectPeopleByUsername(
          this.getUser.getUsername
        );
        for (const peopleProject of peopleProjectsByUser) {
          const project: ProjectCore = this.getProjects.find(
            p => p.getId == peopleProject.getProjectId
          );
          if (project) {
            projectsOfUsername.push(project);
          }
        }
        let result = [];
        for (const project of projectsOfUsername) {
          const lastProjectState: ProjectStateCore =
            this.getLastProjectStateByProjectId(project.getId);
          if (lastProjectState.getState == projectStates.active.name) {
            result.push(project);
          }
        }
        return result;
      }
    },
    methods: {
      async loadProjectsCore(): Promise<any> {
        if (this.getUser.isAdmin()) {
          await this.loadProjects();
        } else {
          const userProjects: ProjectPeopleCore[] = this.getProjectPeople;
          for (const userProject of userProjects) {
            await this.loadProject(userProject.getProjectId);
          }
        }
      },
      projectHeaderService(sName) {
        this.serviceName = sName;
      },
      projectHeaderExtension(sExtension) {
        this.extensionName = sExtension;
      }
    }
  };
